<template>
  <div>
    <div class="d-block" v-html="srcValue" />
  </div>
</template>

<script setup lang="ts">
import { vgBodyCellProps } from "@olc/vue-grid";

const props = defineProps(vgBodyCellProps);

const srcValue = computed(() => {
  return props.ctx.getValue();
});

</script>
