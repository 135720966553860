<template>
  <v-input v-model="files" class="file-display-field">
    <v-field
      v-model="files"
      multiple
      :variant="writable ? 'outlined' : 'plain'"
      :clearable="writable"
    >
      <div class="d-flex flex-wrap w-100 ga-1" :class="writable ? 'pa-2' : ''">
        <v-chip
          v-for="file, i in files"
          :key="i"
          size="small"
          density="comfortable"
          label
          class="px-1"
          :closable="writable"
          @click:close="removeFile(i)"
        >
          <v-tooltip :disabled="!displayThumbnail(file)" location="top">
            <template #activator="{ props: tooltipProps }">
              <NuxtLink v-bind="tooltipProps" :to="getFileUrl(formatFile(file))" class="pa-0">
                {{ formatFile(file) }}
              </NuxtLink>
            </template>
            <v-img
              :src="getFileUrl(formatFile(file))"
              max-width="550"
              max-height="550"
              min-width="30"
              min-height="30"
              cover
            >
              <template #placeholder>
                <div class="d-flex align-center justify-center fill-height position-relative">
                  <v-icon icon="mdi-image-outline" :size="40" />
                </div>
              </template>
            </v-img>
          </v-tooltip>
        </v-chip>
      </div>
    </v-field>
    <template v-if="files.length > 1" #append>
      <v-btn
        text="zip"
        icon="mdi-folder-zip-outline"
        size="small"
        density="compact"
        class="vg-grid-reset"
        title="Stáhnout jako zip"
        :href="zipUrl"
      />
    </template>
  </v-input>
</template>

<script setup lang="ts">
const props = defineProps<{
  writable?: boolean;
  documentationId?: string;
  reportDetailId?: string;
  nemoFileId?: string;
}>();

const { formatFile } = useFormat();
const { files: fileApi } = useApi();

const files = defineModel<(string | File)[]>({
  default: () => []
});

const emit = defineEmits<{
  "update:modelValue": [(string | File)[]]
}>();

const removeFile = (index: number) => {
  files.value.splice(index, 1);
  emit("update:modelValue", files.value);
};

function getFileUrl (filename?: string) {
  if (!filename) {
    return "";
  }
  if (props.documentationId) {
    return fileApi.getDocumentationFileUrl(props.documentationId, filename);
  }
  if (props.reportDetailId) {
    return fileApi.getReportDetailFileUrl(props.reportDetailId, filename);
  }
  if (props.nemoFileId) {
    return fileApi.getNemoFileUrl(props.nemoFileId, filename);
  }
  return "";
}

const zipUrl = computed(() => {
  if (props.reportDetailId) {
    return fileApi.getReportDetailZipUrl(props.reportDetailId);
  }
  if (props.documentationId) {
    return fileApi.getDocumentationZipUrl(props.documentationId);
  }
  if (props.nemoFileId) {
    return fileApi.getNemoFileZipUrl(props.nemoFileId);
  }
  return "";
});

const IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "gif", "webp", "svg", "apng", "avif"];

function displayThumbnail (file: any) {
  if (!file || typeof file !== "string") {
    return false;
  }
  const ext = file.split(".").pop();
  return IMAGE_EXTENSIONS.includes(ext?.toLowerCase() ?? "");
}
</script>

<style>
.v-input--horizontal .v-input__append {
  margin-inline-start: auto;
}

.v-field--appended {
  --v-field-padding-end: 0;
}

.v-chip__content {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
