<template>
  <div :title="srcValue">
    {{ srcValueTranslated }}
  </div>
</template>

<script setup lang="ts">
import { vgBodyCellProps } from "@olc/vue-grid";

const { getGridCellValue } = useUtils();
const props = defineProps(vgBodyCellProps);

const srcValue = computed(() => getGridCellValue(props.ctx));


const srcValueTranslated = computed(() => {
  if (srcValue.value === true) {
    return "Ano";
  } else if (srcValue.value === false) {
    return "Ne";
  } else {
    return "";
  }
});
</script>