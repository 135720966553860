<template>
  <v-spacer v-if="field.type === 'gap'" />
  <FormFieldBool
    v-else-if="field.type === 'bool'"
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    :indeterminate="(field as FormFieldBool).indeterminate"
    class="form-field"
    @update:model-value="val => field.onChange?.(val)"
  />
  <FormFieldDate
    v-else-if="field.type === 'date'"
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    class="form-field"
    @update:model-value="val => field.onChange?.(val)"
  />
  <FormFieldDatetime
    v-else-if="field.type === 'datetime'"
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    class="form-field"
    @update:model-value="val => field.onChange?.(val)"
  />
  <FormFieldTime
    v-else-if="field.type === 'time'"
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    class="form-field"
    @update:model-value="val => field.onChange?.(val)"
  />
  <FormFieldPlan
    v-else-if="field.type === 'plan'"
    v-model="fieldModel"
    :is-update="isUpdate && toValue(field.readonly) !== true"
    :options="baseOptionsComputed"
    class="form-field"
    @update:model-value="val => field.onChange?.(val)"
  />
  <FormFieldEmail
    v-else-if="field.type === 'email'"
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    class="form-field"
    @update:model-value="val => field.onChange?.(val)"
  />
  <FormFieldColor
    v-else-if="field.type === 'color'"
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    class="form-field"
    @update:model-value="val => field.onChange?.(val)"
  />
  <FormFieldTextarea
    v-else-if="field.type === 'textarea' || field.type === 'textarea_html'"
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    :rows="(field as FormFieldTextarea).rows"
    :theme="(field as FormFieldTextarea).theme"
    class="form-field"
    :with-html-editor="field.type === 'textarea_html'"
    @update:model-value="val => field.onChange?.(val)"
  />
  <FormFieldTextareaRegular
    v-else-if="field.type === 'textarea_regular'"
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    :rows="(field as FormFieldTextarea).rows"
    @update:model-value="val => field.onChange?.(val)"
  />
  <FormFieldSelect
    v-else-if="field.type === 'select'"
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    :data-url="(field as FormFieldSelect).dataUrl"
    :items="(field as FormFieldSelect).items"
    :item-value="(field as FormFieldSelect).itemValue!"
    :item-title="(field as FormFieldSelect).itemTitle!"
    :no-objects="(field as FormFieldSelect).noObjects"
    :combo="(field as FormFieldSelect).combo"
    :lazy="(field as FormFieldSelect).lazy"
    class="form-field"
    @update:model-value="(val: any) => field.onChange?.(val)"
  />
  <FormFieldPostCode
    v-else-if="field.type === 'postcode'"
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    class="form-field"
    @update:model-value="val => field.onChange?.(val)"
  />
  <div v-else-if="field.type === 'template'" class="form-field-template">
    <FormFieldSelect
      v-model="(field as FormFieldTemplateForRenderer).templateSelect.model[(field as FormFieldTemplateForRenderer).templateSelect.valueName!]"
      :is-update="isUpdate && !(field as FormFieldTemplateForRenderer).templateSelect.readonly"
      :options="templateSelectOptions"
      :data-url="((field as FormFieldTemplateForRenderer).templateSelect as FormFieldSelect).dataUrl"
      :items="((field as FormFieldTemplateForRenderer).templateSelect as FormFieldSelect).items"
      :item-value="((field as FormFieldTemplateForRenderer).templateSelect as FormFieldSelect).itemValue!"
      :item-title="((field as FormFieldTemplateForRenderer).templateSelect as FormFieldSelect).itemTitle!"
      :no-objects="(field as FormFieldTemplateForRenderer).templateSelect?.noObjects"
      class="form-field"
      @update:model-value="(val: any) => (field as FormFieldTemplateForRenderer).templateSelect.onChange?.(val)"
    />
    <FormFieldTextarea
      v-model="(field as FormFieldTemplateForRenderer).textarea.model[(field as FormFieldTemplateForRenderer).textarea.valueName!]"
      :is-update="isUpdate && !(field as FormFieldTemplateForRenderer).textarea.readonly"
      :options="templateTextareaOptions"
      :rows="((field as FormFieldTemplateForRenderer).textarea as FormFieldTextarea).rows"
      :theme="((field as FormFieldTemplateForRenderer).textarea as FormFieldTextarea).theme"
      class="form-field"
      @update:model-value="(val: any) => (field as FormFieldTemplateForRenderer).textarea.onChange?.(val)"
    />
  </div>
  <FormFieldDifficulty
    v-else-if="field.type === 'difficulty'"
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    class="form-field"
    @update:model-value="val => field.onChange?.(val)"
  />
  <FormFieldFiles
    v-else-if="field.type === 'file'"
    v-model:local-files="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    class="form-field"
    @update:local-files="val => field.onChange?.(val)"
    @update:hosted-files="val => field.onChange?.(val)"
  />
  <FormFieldTextOnly
    v-else-if="field.type === 'textonly'"
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    :type="field.type"
    class="form-field"
    @update:model-value="val => field.onChange?.(val)"
  />
  <FormFieldText
    v-else
    v-model="fieldModel"
    :is-update="isUpdate && !toValue(field.readonly)"
    :options="baseOptionsComputed"
    :type="field.type"
    class="form-field"
    @update:model-value="val => field.onChange?.(val)"
  />
</template>

<script setup lang="ts">
defineProps<{
  isUpdate: boolean
}>();
const field = defineModel<FormFieldForRenderer>({ required: true });
const { extractBaseOptions, extractBaseOptionsAsync } = useForms();

const fieldModel = computed({
  get: () => {
    if (field.value.valueName) {
      return field.value.model[field.value.valueName];
    }
    return field.value.model;
  },
  set: (val) => {
    if (field.value.valueName) {
      field.value.model[field.value.valueName] = val;
    } else {
      field.value.model = val;
    }
  }
});

const baseOptionsComputed = computedAsync(async () => {
  return await extractBaseOptionsAsync(field.value);
}, extractBaseOptions(field.value));

const templateSelectOptions = computedAsync(() => {
  return extractBaseOptionsAsync((field.value as FormFieldTemplateForRenderer).templateSelect);
}, extractBaseOptions(field.value));
const templateTextareaOptions = computedAsync(() => {
  return extractBaseOptionsAsync((field.value as FormFieldTemplateForRenderer).textarea);
}, extractBaseOptions(field.value));
</script>
