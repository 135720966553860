import { throttle } from "lodash-es";
import { defineStore } from "pinia";
import type { components } from "~/types/api-spec";

type Center = components["schemas"]["SimpleCenter"];

function parseJwt (token: string | null) {
  if (token === null) {
    return null;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(""));

  return JSON.parse(jsonPayload);
}

export default defineStore("auth", () => {
  const api = useApi();
  const { TTL_DAY } = useTTL();

  const STATES = {
    loading: 0,
    authenticated: 1,
    unauthenticated: 2,
    needsMfaVerify: 3,
    needsMfaSetup: 4
  };

  // ################### STATE ###################
  const data = useState<(SessionData & {own_center?: Center}) | null>("auth:data", () => null);
  const lastRefreshedAt = useState<Date | null>("auth:lastRefreshedAt", () => {
    return data.value ? new Date() : null;
  });
  const loading = useState<boolean>("auth:loading", () => false);
  const accessTokenCookie = useCookie<string | null>("auth:access-token", { default: () => null, maxAge: TTL_DAY * 7 });
  const accessToken = useState<string | null>("auth:access-token", () => accessTokenCookie.value);
  watch(accessToken, async (value) => {
    if (toValue(value) !== accessTokenCookie.value) {
      await nextTick();
      accessTokenCookie.value = toValue(value);
    }
  });
  // max-age of cookie can be at most 400 days on Chrome
  const refreshTokenCookie = useCookie<string | null>("auth:refresh-token", { default: () => null, maxAge: TTL_DAY * 400 });
  const refreshToken = useState<string | null>("auth:refresh-token", () => refreshTokenCookie.value);
  watch(refreshToken, async (value) => {
    if (toValue(value) !== refreshTokenCookie.value) {
      await nextTick();
      refreshTokenCookie.value = toValue(value);
    }
  });

  // ################### COMPUTED ###################
  const accessTokenHeader = computed<string | null>(() => {
    if (accessToken.value === null) {
      return null;
    }
    return `Bearer ${accessToken.value}`;
  });
  const mfaMethod = computed<string>(() => {
    if (data.value) {
      return data.value.mfa_method;
    }
    const jwtData = parseJwt(accessToken.value);
    return jwtData?.mfa_method ?? "";
  });
  const isMfaVerified = computed<boolean>(() => {
    const jwtData = parseJwt(accessToken.value);
    return jwtData?.mfa_verified ?? false;
  });
  const status = computed<number>(() => {
    if (loading.value) {
      return STATES.loading;
    } else if (data.value) {
      return STATES.authenticated;
    } else if (accessToken.value && !isAccessTokenExpired() && mfaMethod.value) {
      return STATES.needsMfaVerify;
    } else if (accessToken.value && !isAccessTokenExpired() && !mfaMethod.value) {
      return STATES.needsMfaSetup;
    } else {
      return STATES.unauthenticated;
    }
  });
  const isLoggedIn = computed(() => status.value === STATES.authenticated || (status.value === STATES.loading && !!data.value));

  // ################### FUNCTIONS ###################
  function isAccessTokenExpired (): boolean {
    if (accessToken.value === null || accessToken.value === undefined) { return true; }
    const BUFFER_MS = 5000;
    return Date.now() + BUFFER_MS >= parseJwt(accessToken.value).exp * 1000;
  }

  async function refreshAccessToken (): Promise<boolean> {
    if (!refreshToken.value || !accessToken.value) {
      return false;
    }

    const { data: refreshData, error } = await api.auth.tokenRefresh(accessToken.value, refreshToken.value);

    if (error.value) {
      await internalLogout();
      return false;
    }

    if (refreshData.value) {
      const access = refreshData.value.access;
      accessToken.value = access;
      return true;
    }
    return false;
  }

  const refreshAccessTokenThrottled = throttle(refreshAccessToken, 500);

  async function getSession () {
    if (loading.value || !accessTokenHeader.value || !isMfaVerified.value) {
      return;
    }
    loading.value = true;
    const nuxtApp = useNuxtApp();
    if (isAccessTokenExpired() && !await refreshAccessToken()) {
      data.value = null;
      loading.value = false;
      return;
    }

    const { data: sessionData, error } = await nuxtApp.runWithContext(api.user.getSession);
    if (sessionData.value) {
      data.value = sessionData.value;
    }
    if (error.value) {
      data.value = null;
      accessToken.value = null;
      refreshToken.value = null;
    }
    loading.value = false;
    lastRefreshedAt.value = /* @__PURE__ */ new Date();
  }

  async function internalLogout () {
    data.value = null;
    accessToken.value = null;
    refreshToken.value = null;
    await nextTick();
  }

  /**
   * This function must be used instead of useAuth().signOut() because the user's refresh token must be
   * sent to be blacklisted.
   */
  async function logout () {
    if (accessTokenHeader.value !== null) {
      if (isAccessTokenExpired()) {
        await refreshAccessToken();
      }
      await api.user.logout();
    }
    await internalLogout();
    navigateTo(useLocalePath()({ name: "index" }));
  }

  function hasPermission (permission: string): boolean {
    if (!data.value) { return false; }
    return data.value.permissions.includes(permission);
  }

  async function resetMfa () {
    await api.user.resetMfa();
    await logout();
  }

  function getCurrentUser () {
    return {
      id: data.value?.id,
      username: data.value?.username,
      email: data.value?.email,
      first_name: data.value?.first_name,
      last_name: data.value?.last_name,
      display_name: data.value?.display_name,
      is_staff: data.value?.is_staff || data.value?.is_superuser
    };
  }

  const getOwnCenter = async () => {
    if (data.value?.own_center || !data.value?.id) {
      return data.value?.own_center;
    }
    const { centers } = useCommonFormData();
    const center = await centers.getAsync({ params: { partner__locations__location__dispatcher: data.value?.id } });
    if (center && Array.isArray(center)) {
      return center[0];
    }
    return null;
  };

  return {
    STATES,
    sessionData: data,
    loading,
    accessToken,
    accessTokenCookie,
    refreshToken,
    refreshTokenCookie,
    accessTokenHeader,
    lastRefreshedAt,
    mfaMethod,
    status,
    isMfaVerified,
    isLoggedIn,
    isAccessTokenExpired,
    refreshAccessToken: refreshAccessTokenThrottled,
    logout,
    getSession,
    hasPermission,
    resetMfa,
    getCurrentUser,
    parseJwt,
    getOwnCenter
  };
});
