import type { components } from "~/types/api-spec";

export function useFormValidators () {
  const { t } = useNuxtApp().$i18n; // cannot use useI18n() outside of script setup
  const { isEmptyVal } = useUtils();

  function convertValue (v: any): any {
    if (typeof v === "string") {
      return parseFloat(v.replace(/,/g, ".").replace(/\s+/g, "")); // convert input with coma to dot e.g. 5,5 => 5.5
    }
    return v;
  }

  const postCodeRegex = /^\d{5}$|^\d{3} \d{2}$/;

  function testImei (value: any) {
    const len = value.length;
    const parity = len % 2;
    let sum = 0;
    for (let i = len - 1; i >= 0; i--) {
      let d = parseInt(value.charAt(i), 10);
      if (i % 2 === parity) {
        d *= 2;
      }
      if (d > 9) {
        d -= 9;
      }
      sum += d;
    }
    return sum % 10 === 0;
  }

  function testBankAccountNumber (value: any) {
    const match = value.match(/^((\d{0,6})-)?(\d{1,10})$/);
    if (!match) {
      return false;
    }

    const rawPrefix = match[2] || "";
    const rawBankNumber = match[3] || "";
    const prefix = rawPrefix.padStart(6, "0");
    const bankNumber = rawBankNumber.padStart(10, "0");

    const isOkFirst = (10 * prefix[0] + 5 * prefix[1] + 8 * prefix[2] + 4 * prefix[3] + 2 * prefix[4] + 1 * prefix[5]) % 11 === 0;
    if (!isOkFirst) {
      return false;
    }
    return (6 * bankNumber[0] + 3 * bankNumber[1] + 7 * bankNumber[2] + 9 * bankNumber[3] + 10 * bankNumber[4] + 5 * bankNumber[5] + 8 * bankNumber[6] + 4 * bankNumber[7] + 2 * bankNumber[8] + 1 * bankNumber[9]) % 11 === 0
    ;
  }

  const BANK_CODES = ["0100", "0300", "0600", "0710", "0800", "2010", "2020", "2030", "2060", "2070", "2100", "2200", "2220", "2240", "2250", "2260", "2310", "2600", "2700", "3030", "3050", "3060", "3500", "4000", "4300", "5500", "5800", "6000", "6100", "6200", "6210", "6300", "6700", "6800", "7910", "7940", "7950", "7960", "7970", "7980", "7990", "8030", "8040", "8060", "8090", "8150", "8200", "8220", "8230", "8240", "8250"];

  function testBankCode (value: any) {
    return BANK_CODES.includes(value);
  }

  const validationRules = {
    required: (value: any): boolean | string => !isEmptyVal(value) || t("form.validation.required"),
    requiredNonZero: (value: any): boolean | string => (!isEmptyVal(value) && value !== 0) || t("form.validation.required"),
    max: (maxLength: number) => (value: any): boolean | string => !value || (value && value.length <= maxLength) || t("form.validation.max"),
    min: (minLength: number) => (value: any): boolean | string => !value || (value && value.length >= minLength) || t("form.validation.min"),
    maxValue: (maxValue: number) => (value: any): boolean | string => (value !== undefined && value !== null && (convertValue(value) <= maxValue)) || t("form.validation.maxValue"),
    minValue: (minValue: number) => (value: any): boolean | string => (value !== undefined && value !== null && (convertValue(value) >= minValue)) || t("form.validation.minValue"),
    maxValueFloat: (maxValue: number) => (value: any): boolean | string => (value !== undefined && value !== null && (convertValue(value) <= maxValue)) || t("form.validation.maxValue"),
    minValueFloat: (minValue: number) => (value: any): boolean | string => (value !== undefined && value !== null && (convertValue(value) >= minValue)) || t("form.validation.minValue"),
    isDecimal: (value: any): boolean | string => !value || (value && /^\d*([,.]\d+)?$/.test(value)) || t("form.validation.isNumber"),
    isDecimalOrNA: (value: any): boolean | string => { if (value === "N/A") { return true; } if (!value) { return true; } return /^\d*([.,]\d+)?$/.test(value) || t("form.validation.isNumber"); },
    isInteger: (value: any): boolean | string => !value || (value && /^\d+$/.test(value)) || t("form.validation.isInteger"),
    isIntegerOrNA: (value: any): boolean | string => { if (value === "N/A") { return true; } if (!value) { return true; } return /^\d+$/.test(value) || t("form.validation.isInteger"); },
    isPositiveNumber: (value: any): boolean | string => !value || (value && /^\d+$/.test(value)) || t("form.validation.isPositiveNumber"),
    isPositiveFloatOrDecimalNumber: (value: any): boolean | string => !value || (value && /^\d+(\.\d+)?$/.test(value)) || t("form.validation.isPositiveNumber"),
    valueIsNotOnlyNumbers: (value: any): boolean | string => !value || (value && !/^\d*$/.test(value)) || t("form.validation.valueIsNotOnlyNumbers"),
    postCode: (value: any): boolean | string => !value || (value && postCodeRegex.test(value)) || t("form.validation.postCode"),
    isEmail: (value: any): boolean | string => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!value) {
        return true;
      }
      return pattern.test(value as string) || t("form.validation.email");
    },
    validateGPS: (value: any, prefix: string): boolean | string => {
      if (!value) {
        return true;
      }
      value = value.toString();
      if (value.length > 2 && value[2] !== ".") {
        value = value.slice(0, 2) + "." + value.slice(2);
      }
      if (value.length > 9) {
        return t("form.validation.isNotValidGPSFormat");
      }
      const gps = parseFloat(value);
      if (prefix === "N") {
        if (gps < 48.603424 || gps > 51.123041) {
          return t("form.validation.isNotInCZ");
        }
      } else if (prefix === "E") {
        if (gps < 12.031065 || gps > 18.826183) {
          return t("form.validation.isNotInCZ");
        }
      }
      return true;
    },
    planChecked (value: any): boolean | string {
      if (value.includes("True")) {
        return true;
      } else {
        return t("form.validation.planChecked");
      }
    },
    isPhoneNumber: (value: any): boolean | string => !value || (!!value && /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(value as string)) || t("form.validation.isPhoneNumber"),
    isLink: (value: any): boolean | string => !value || (!!value && /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi.test(value as string)) || t("form.validation.isLink"),
    isIMEIValid: (value: any): boolean | string => !value || (value && testImei(value)) || t("form.validation.isIMEIValid"),
    bankAccountNumber: (value: any): boolean | string => !value || (value && testBankAccountNumber(value)) || t("form.validation.bankAccountNumber"),
    bankCode: (value: any): boolean | string => !value || (value && testBankCode(value)) || t("form.validation.bankCode"),
    nameAndSurname: (value: any): boolean | string => !value || (!!value && /^\S+\s+\S+[a-zA-Z., ]*$/.test(value as string)) || t("form.validation.nameAndSurname"),
    maxLength: (maxLength: number) => (value: any): boolean | string => !value || (!!value && value.length < maxLength) || t("form.validation.maxLength"),
    isNotInPast: (value: any): boolean | string => {
      const date = new Date(value);
      const now = new Date();
      return date >= now || t("form.validation.isNotInPast");
    },
    isNotInFuture: (value: any): boolean | string => {
      const date = new Date(value);
      const now = new Date();
      return date <= now || t("form.validation.isNotInFuture");
    },
    isOtherZero: (value: any, otherValue: any): boolean | string => {
      if (otherValue === 0) {
        return (value > 0) || t("form.validation.required");
      }
      return !isEmptyVal(value) || t("form.validation.required");
    },
    isSumGreaterThanZero: (value: any[]): boolean | string => value.reduce(
      (acc, curr) => acc + parseFloat((curr ?? "0").toString()), 0) > 0 || t("form.validation.isSumGreaterThanZero"),
    invoiceSettlement: (value: any, row: any, gridApi: any, totalValue: number): boolean | string => {
      const rows = gridApi.table?.value?.getRowModel().flatRows;
      let amounts = 0;

      rows.forEach((r: any) => {
        if (r.original?.id !== row.id && r.original?.economic_centre?.code !== "104" && r.id !== "_sum") {
          amounts += r.original?.amount ?? 0;
        }
      });


      const finalAmount = Number(totalValue) - Number(amounts) - (row.economic_centre?.code !== "104" ? Number(value) : 0);

      if (finalAmount < 0) {
        return t("form.validation.isCountGreaterThanInvoiceValue");
      }

      return true;
    },
    isRowInGrid: (val: any, row: any, grid: any, key: string): boolean | string => {
      if (!val) {
        return true;
      }
      const rows = grid.table?.value?.getRowModel().flatRows;
      const rowExists = rows?.some((r: any) => {
        if (r.original.id === row.id) {
          return false;
        }
        return toRaw(r.original)[key] && toRaw(r.original)[key].id === val.id;
      });
      return rowExists ? t("form.validation.isRowInGrid") : true;
    }
  };

  type ValueCtx<V> = {
    value: V | null | undefined;
    label: string;
  };

  const ctxValidationRules = {
    dateBeforeDate: (v1: ValueCtx<string>, v2: ValueCtx<string>) => {
      return (!v1.value || !v2.value || new Date(v1.value) < new Date(v2.value)) || `"${v1.label}" musí být před "${v2.label}"`;
    },
    dateAfterDate: (v1: ValueCtx<string>, v2: ValueCtx<string>) => {
      return (!v1.value || !v2.value || new Date(v1.value) > new Date(v2.value)) || `"${v1.label}" musí být po "${v2.label}"`;
    },
    dateAfterOrEqualDate: (v1: ValueCtx<string>, v2: ValueCtx<string>) => {
      const date1 = v1.value ? new Date(v1.value) : null;
      const date2 = v2.value ? new Date(v2.value) : null;
      if (date1 && date2) {
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
        return (date1 >= date2) || `"${v1.label}" musí být po nebo rovno "${v2.label}"`;
      }
      return false;
    }
  };

  type EventDetail = components["schemas"]["EventDetail"];
  type ReportDetail = components["schemas"]["GridReportDetail"];
  type PartnerDetail = components["schemas"]["PartnerDetail"];
  const { EventTypePID: EVENT_TYPES, PartStatePID: PART_STATES } = useNuxtApp().$const.administration;

  const specificRules = {
    reportDetail: {
      photo: (partData: DeepPartial<ReportDetail["part"]>, eventData?: EventDetail, partnerData?: PartnerDetail) => (value: File) => {
        if (!value && partData) {
          if ((eventData && eventData.event_type.persistent_id === EVENT_TYPES.PREVENTIVE_INSPECTION) &&
            (partnerData && partnerData.photo_pp) &&
            partData.part_type?.part_group?.photo_pp
          ) {
            return "Musí obsahovat fotografii z PP.";
          }
          if ((eventData && eventData.event_type.persistent_id === EVENT_TYPES.ASSEMBLY) &&
            (partnerData && partnerData.photo_montage) &&
            partData.part_type?.part_group?.photo_assembly
          ) {
            return "Musí obsahovat fotografii z montáže.";
          }
        }
        return true;
      },
      photoRevisionBook: (reportDetailData: DeepPartial<ReportDetail>, eventData?: EventDetail) => (value: File[] | null) => {
        if (eventData?.event_type.photo_revision_book &&
          reportDetailData.part &&
          reportDetailData.part.part_type?.part_group?.photo_revision_book &&
          (!value || (Array.isArray(value) && value.length < 2 && !(value.length === 1 && value[0].name.endsWith(".pdf"))))
        ) {
          return "Musí obsahovat alespoň dvě fotografie.";
        }
        return true;
      },
      details: (reportDetailData: DeepPartial<ReportDetail>, eventData?: EventDetail) => (value: string) => {
        if (!value &&
          (eventData && eventData.event_type.persistent_id !== EVENT_TYPES.PRE_INSTALLATION) &&
          reportDetailData.part &&
          reportDetailData.part.part_type?.part_group?.report_status &&
          reportDetailData.part_state
        ) {
          if (reportDetailData.part_state.persistent_id === PART_STATES.FUNCTIONAL_WITH_RESERVATION) {
            return "Uveďte výhradu k funkčnosti dílu.";
          }
          if (reportDetailData.part_state.persistent_id === PART_STATES.NON_FUNCTIONAL) {
            return "Uveďte podrobnosti ke stavu dílu.";
          }
        }
        return true;
      }
    }
  };

  return {
    validationRules,
    ctxValidationRules,
    specificRules
  };
}
