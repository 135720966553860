<template>
  <div :title="formattedValue">
    {{ formattedValue }}
  </div>
</template>

<script setup lang="ts">
import { vgBodyCellProps } from "@olc/vue-grid";

const { getGridCellValue } = useUtils();
const props = defineProps({
  ...vgBodyCellProps,
  precision: {
    type: Number,
    default: 0
  }
});

const formattedValue = computed(() => {
  const value = getGridCellValue(props.ctx);
  if (value === null || value === undefined || value === "") {
    return "";
  }
  return `${Number(value).toFixed(props.precision)} Kč`;
});
</script>
