import { assign, get as deepGet, set, upperFirst } from "lodash-es";
import { useFormValidators } from "./useFormValidators";

export function useForms () {
  const { validationRules } = useFormValidators();
  // const { getCurrentUser } = useAuthStore();
  // const { hashCode } = useUtils();

  const drillPath = (root: object, path: string, valIfUndefined?: any) => {
    if (deepGet(root, path) === undefined) {
      set(root, path, valIfUndefined);
    }

    const pathArr = path.split(".");
    const suffix = pathArr.pop();
    const prefix = pathArr.join(".");

    return { model: prefix ? deepGet(root, prefix) : root, valueName: suffix };
  };

  /**
   * Return form fields linked to the reactive object, whilst shaping the object in-place.
   */
  function initializeFields<T extends Record<string, any>> (
    reactiveData: T,
    fields: FormField<T>[]
  ): FormFieldForRenderer<T>[] {
    return fields.filter(field => !field.hidden).map((field) => {
      if (field.type === "template") {
        const field_ = field as (FormFieldBase<T> & FormFieldTemplate<T>);
        field_.templateSelect = initializeFields(reactiveData, [field_.templateSelect])[0] as (FormFieldBase<T> & FormFieldSelect<T>);
        field_.textarea = initializeFields(reactiveData, [field_.textarea])[0] as (FormFieldBase<T> & FormFieldTextarea);
        return field_;
      }

      let defaultVal = field.default;
      if (field.default === undefined) {
        switch (field.type) {
        case "textarea":
        case "text":
        case "email":
          defaultVal = "";
          break;
        case "bool":
          defaultVal = (field as FormFieldBool).indeterminate ? null : false;
          break;
        default:
          defaultVal = null;
          break;
        }
      }

      return {
        ...field,
        ...field.path ? drillPath(reactiveData, field.path, defaultVal) : {}
      } as FormFieldForRenderer<T>;
    }) as FormFieldForRenderer<T>[];
  }

  function extractBaseOptions (field: FormField | FormFieldForRenderer): FormFieldBaseOptionsRaw {
    return {
      ...field,
      label: upperFirst(field.label),
      readonly: toValue(field.readonly) ?? false,
      required: toValue(field.required) ?? false,
      placeholder: field.placeholder ?? "",
      autofocus: field.autofocus ?? false,
      messages: "",
      rules: field.rules ?? [],
      readonlyFormatter: field.readonlyFormatter,
      link: toValue(field.link),
      tooltip: field.tooltip,
      defaultGetter: field.defaultGetter,
      index: field.index,
      inputIcon: toValue(field.inputIcon)
    };
  }

  async function extractBaseOptionsAsync (field: FormField | FormFieldForRenderer): Promise<FormFieldBaseOptionsRaw> {
    return {
      ...field,
      label: upperFirst(field.label),
      readonly: toValue(field.readonly) ?? false,
      required: toValue(field.required) ?? false,
      placeholder: field.placeholder ?? "",
      autofocus: field.autofocus ?? false,
      messages: await toValue(field.messages) ?? "",
      rules: field.rules ?? [],
      readonlyFormatter: field.readonlyFormatter,
      link: toValue(field.link),
      tooltip: field.tooltip,
      defaultGetter: field.defaultGetter,
      index: field.index,
      inputIcon: toValue(field.inputIcon)
    };
  }

  async function getFieldClasses (rules: SimpleInputRule[], value: any) {
    const BASE_CLASSES = ["form-field"];
    if (rules.length === 0) {
      return [...BASE_CLASSES];
    }
    let valid = true;
    let isRequired = false;
    for (const rule of rules) {
      if (rule === validationRules.required) {
        isRequired = true;
      }
      if (await rule(value) !== true) {
        valid = false;
      }
    }
    if (!valid) {
      return [...BASE_CLASSES, "field-required"];
    } else if (valid && isRequired) {
      return [...BASE_CLASSES, "field-required field-required-filled"];
    }
    return [...BASE_CLASSES];
  }

  const getFieldCssClass = (field: FormFieldBase) => {
    if (field.cssClass) {
      return field.cssClass;
    }
    // if (field.type === 'number') {
    //   return 'v-col-sm-1 v-col-lg-1';
    // }
    // if (field.type === 'date') {
    //   return 'v-col-sm-1 v-col-lg-1';
    // }
    // if (field.type === 'textarea') {
    //   return 'v-col-sm-3 v-col-lg-4';
    // }
    // if (field.type === 'select') {
    //   return 'v-col-sm-3 v-col-lg-2';
    // }
    return "v-col-sm-4 v-col-lg-3";
  };

  const getRulesWithRequired = (required?: boolean | MaybeRefOrGetter<boolean>, rules: SimpleInputRule[] = []) => {
    required = toValue(required);
    if (typeof required === "object" && "value" in required) {
      required = (required as any).value;
    }
    if (!required) {
      return rules;
    }
    return [
      validationRules.required,
      ...rules
    ];
  };

  const openDialog = (dialogState: DialogState, toOpen: Partial<DialogState>) => {
    assign(dialogState, toOpen);
    dialogState.isOpen = true;
  };

  // function getFormHash (id: string) {
  //   return hashCode(id + getCurrentUser().id);
  // }

  // const saveToSessionStorage = (id: string, formData: any) => {
  //   const formString = JSON.stringify(formData);
  //   sessionStorage.setItem(getFormHash(id), formString);
  // };

  // const loadFromSessionStorage = (id: string): Record<string, any> | null => {
  //   const formString = sessionStorage.getItem(getFormHash(id));
  //   if (!formString) {
  //     return null;
  //   }
  //   return JSON.parse(formString);
  // };

  const actionItemLoading = ref(false);

  async function callActionItem (item: _ActionItemBase, confirmDialogEmit: (data: DialogState | DialogConfirmState) => void, formValidateExternallyFn?: FormBaseValidateExternallyFn) {
    if (!item.action) {
      return;
    }

    async function callWithLoad () {
      if (item.action) {
        actionItemLoading.value = true;
        const res = await item.action();
        if (res) {
          useNotifier().error(res);
        }
      }
      actionItemLoading.value = false;
    }

    actionItemLoading.value = true;
    if (formValidateExternallyFn) {
      if (!await formValidateExternallyFn(item.formValidators ?? [])) {
        actionItemLoading.value = false;
        return;
      }
    } else {
      for (const validator of [...item.formValidators ?? []].sort(a => (a.isWarning ? 1 : -1))) {
        if (!await validator.valid()) {
          const msg = toValue(validator.message);
          if (validator.isWarning) {
            useNotifier().warning(msg, null, { notificationOptions: { duration: 3500 } });
          } else {
            useNotifier().error(msg, null, { notificationOptions: { duration: 3500 } });
            actionItemLoading.value = false;
            return;
          }
        }
      }
    }

    if (item.confirmBeforeAction !== undefined && item.confirmBeforeAction !== null) {
      confirmDialogEmit({
        body: item.confirmBeforeAction,
        isOpen: true,
        onConfirm: callWithLoad
      });
    } else {
      await callWithLoad();
    }
    actionItemLoading.value = false;
  }

  return {
    initializeFields,
    extractBaseOptions,
    extractBaseOptionsAsync,
    getFieldClasses,
    getFieldCssClass,
    getRulesWithRequired,
    openDialog,
    callActionItem,
    actionItemLoading
    // saveToSessionStorage,
    // loadFromSessionStorage
  };
}
