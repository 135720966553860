import {type InjectionKey, type Ref, type ComputedRef, type WritableComputedRef, ref} from "vue";
import type { GridApi } from "@olc/vue-grid";
import type { components } from "~/types/api-spec";
import type { NemoTableConfig } from "~/components/nemo/NemoTable.vue";

type ExpansionPanelTooltipData = {
    name: string,
    contentComponent: any,
    props: {
        key: string,
        value: string,
    }[],
    params: Record<string, string | number>
}

type FormDetailCtx<D> = {
  /** formDataOriginal - updated on form save */
  data: D;
  /** formData - could hold unvalidated edit data */
  liveData?: D;
  /** Refresh form with data from the server */
  refresh?: () => void;
}


interface PhotoOrFile {
    filename: string;
    documentation: string;
}

type NemoRecordActionRecord = {
    group_index: number | null;
    mp_vykaz_datum: string | null;
    mp_vykaz_cas_od: string | null;
    mp_vykaz_cas_do: string | null;
    mp_vykaz_ujete_km: string | null;
    mp_vykaz_technik: string[] | null;
    mp_vykaz_uzavren: string | null;
    mp_podpis_technika_datum: string | null;
    mp_podpis_technika_jmeno: string | null;
    mp_podpis_technika: string | null;
    mp_podpis_zakaznika_datum: string | null;
    mp_podpis_zakaznika_jmeno: string | null;
    mp_podpis_zakaznika: string | null;
    mp_vykaz_popis_prace: string[] | null;
    material: any[] | null;
    photos: PhotoOrFile[] | null;
    files: PhotoOrFile[] | null;
};
export const SYM_FORM_BASE_CTX = Symbol("SYM_FORM_BASE_CTX") as InjectionKey<FormBaseCtx>;

// ##################### Forms #####################
type FormDetailLocationCtx = FormDetailCtx<ComputedRef<Partial<components["schemas"]["Location"]>>>;
export const SYM_FORM_DETAIL_LOCATION = Symbol("SYM_FORM_DETAIL_LOCATION") as InjectionKey<FormDetailLocationCtx>;

type FormDetailEventCtx = FormDetailCtx<ComputedRef<components["schemas"]["EventDetail"]>>;
export const SYM_FORM_DETAIL_EVENT = Symbol("SYM_FORM_DETAIL_EVENT") as InjectionKey<FormDetailEventCtx>;

type FormDetailCenterCtx = FormDetailCtx<ComputedRef<components["schemas"]["Center"]>>;
export const SYM_FORM_DETAIL_CENTER = Symbol("SYM_FORM_DETAIL_CENTER") as InjectionKey<FormDetailCenterCtx>;

type FormDetailOfferCtx = FormDetailCtx<ComputedRef<components["schemas"]["OfferDetail"]>> & {
  saveCounter: Ref<number>;
};
export const SYM_FORM_DETAIL_OFFER = Symbol("SYM_FORM_DETAIL_OFFER") as InjectionKey<FormDetailOfferCtx>;

// ##################### Other #####################
export const SYM_LOCATION_DIFFICULTY_MONTH = Symbol("SYM_LOCATION_DIFFICULTY_MONTH") as InjectionKey<Ref<number>>;

type DispatchersType = components["schemas"]["Dispatcher"];
export const SYM_SELECTED_DISPATCHERS = Symbol("SYM_SELECTED_DISPATCHERS") as InjectionKey<Ref<DispatchersType[]>>;
export const SYM_ROUTE_MESSAGE_ID = Symbol("SYM_ROUTE_MESSAGE_ID") as InjectionKey<Ref<string>>;
export const SYM_INVOICE_FINAL_PRICE = Symbol("SYM_INVOICE_FINAL_PRICE") as InjectionKey<Ref<number>>;

export const SYM_IS_WIP_OFFER = Symbol("SYM_IS_WIP_OFFER") as InjectionKey<() => ComputedRef<boolean>>;

export const SYM_LOCATION_RECALCULATE = Symbol("SYM_LOCATION_RECALCULATE") as InjectionKey<WritableComputedRef<string>>;

export const SYM_EVENT_TASK_ROW = Symbol("SYM_EVENT_TASK_ROW") as InjectionKey<ComputedRef<components["schemas"]["EventTask"]>>;

export const INVOICE_RECORD_GRID_ID = "invoice_records";

export const SYM_NEMO_TABLE_CONFIG = Symbol("SYM_NEMO_TABLE_CONFIG") as InjectionKey<Ref<NemoTableConfig>>;

export const SYM_SELECTED_DATES = Symbol("SYM_SELECTED_DATES") as InjectionKey<Ref<string[]>>;
export const SYM_SELECTED_CENTERS = Symbol("SYM_SELECTED_CENTERS") as InjectionKey<Ref<string[]>>;
export const SYM_EVENT_GRID_API = Symbol("SYM_EVENT_GRID_API") as InjectionKey<{ gridApi: GridApi<components["schemas"]["LocationPartners"]> }>;
export const SYM_EVENT_PHASE_GRID = Symbol("SYM_EVENT_PHASE_GRID") as InjectionKey<GridApi<components["schemas"]["Event"]>>;
export const SYM_LOCATION_DEVICE_GRID_API = Symbol("SYM_LOCATION_DEVICE_GRID_API") as InjectionKey<{ gridApi: GridApi<components["schemas"]["LocationDevice"]> }>;
export const SYM_PART_GRID_API = Symbol("SYM_PART_GRID_API") as InjectionKey<{ gridApi: GridApi<components["schemas"]["Part"]> }>;
export const SYM_LOCATION_RELOAD_DETAIL = Symbol("SYM_LOCATION_RELOAD_DETAIL") as InjectionKey<Ref<() => void>>;

export const SYM_SERVICE_MAP_SHOW_SELECTED = Symbol("SYM_SERVICE_MAP_SHOW_SELECTED") as InjectionKey<Ref<boolean>>;
export const SYM_EXPANSION_PANEL_TOOLTIP_DATA = Symbol("SYM_EXPANSION_PANEL_TOOLTIP_DATA") as InjectionKey<Ref<ExpansionPanelTooltipData>>;
export const SYM_SERVICE_MAP_CELL_LISTENER = Symbol("SYM_SERVICE_MAP_CELL_LISTENER") as InjectionKey<Ref<string | null>>;
export const SYM_DEPARTURE_ACTIONS_PRESELECTED = Symbol("SYM_DEPARTURE_ACTIONS_PRESELECTED") as InjectionKey<string>;
export const SYM_LOAD_ACTION_DETAIL_RECORD = Symbol("SYM_LOAD_ACTION_DETAIL_RECORD") as InjectionKey<Ref<NemoRecordActionRecord[]>>;
