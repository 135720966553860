import type {ThemeDefinition} from "vuetify";
import {createVuetify} from "vuetify";
import {cs} from "vuetify/locale";
import {mdi} from "vuetify/iconsets/mdi";
import {iconSet} from "~/iconset";

export default defineNuxtPlugin((nuxtApp) => {
  const commonColors = {
    white: "#ffffff",
    orange: "#D35C20"
  };

  const gridRowColors = {
    light: {
      gRowOfferVariant0: "#fdd",
      gRowOfferVariant1: "#ddf",
      gRowOfferVariant2: "#dfd",
      gRowOfferVariant3: "#dff",
      gRowOfferVariant4: "#ffd",
      gRowOfferVariant5: "#fdf",
      gRowOfferVariant6: "#f9f",
      gRowOfferVariant7: "#9ff",
      gRowTaskReady: "#BBCCBB",
      gRowTaskReadyDetail: "#b2c2b2"
    },
    dark: {
      gRowOfferVariant0: "#240000",
      gRowOfferVariant1: "#000024",
      gRowOfferVariant2: "#002400",
      gRowOfferVariant3: "#002424",
      gRowOfferVariant4: "#242400",
      gRowOfferVariant5: "#240024",
      gRowOfferVariant6: "#660066",
      gRowOfferVariant7: "#006666",
      gRowTaskReady: "#324332",
      gRowTaskReadyDetail: "#3d4d3d"
    }
  };

  const serviceColors = {
    light: {
      center102: "#add",
      center106: "#fdb",
      serviceHolidayText: "#f00",
      serviceWeekendBg: "#d4d4d4",
      serviceWeekendText: "#00f",
      serviceToday: "#9fbdbd",
      serviceActionExternal: "#cfc",
      serviceShift: "#ffc",
      serviceAbsence: "#fcc",
      serviceHighlight: "#ee8",
      serviceDateHighlight: "#f00"
    },
    dark: {
      center102: "#215454",
      center106: "#462f00",
      serviceHolidayText: "#f00",
      serviceWeekendBg: "#3b3b3b",
      serviceWeekendText: "#8edaff",
      serviceToday: "#426161",
      serviceActionExternal: "#406D42",
      serviceShift: "#76774B",
      serviceAbsence: "#643d3e",
      serviceHighlight: "#63642a",
      serviceDateHighlight: "#f00"
    }
  };

  const departmentColors = {
    eventTasks: {
      light: {
        task1: "#f77",
        task2: "#faa",
        task3: "#d39",
        task4: "#ead",
        task5: "#f93",
        task6: "#fda",
        task7: "#9ff",
        task8: "#7cf",
        task9: "#aaf",
        task10: "#ccf",
        task11: "#7b7",
        task12: "#ada",
        task13: "#9c3",
        task14: "#dca",
        task15: "#dc5",
        task16: "#eea"
      },
      dark: {
        // task1: "#f77",
        task1: "#936666",
        task2: "#faa",
        task3: "#d39",
        task4: "#ead",
        task5: "#f93",
        task6: "#fda",
        // task7: "#47f",
        task7: "#374e8a",
        task8: "#7cf",
        task9: "#aaf",
        task10: "#ccf",
        // task11: "#7b7",
        task11: "#688d68",
        task12: "#ada",
        task13: "#9c3",
        task14: "#dca",
        // task15: "#cc5",
        task15: "#77774a",
        task16: "#eea"
      }
    }
  };

  const LightTheme: ThemeDefinition = {
    dark: false,
    colors: {
      ...commonColors,
      ...gridRowColors.light,
      ...serviceColors.light,
      ...departmentColors.eventTasks.light,
      blackWhite: "#000",
      whiteBlack: "#fff",
      primary: "#006794",
      secondary: "#0acc95",
      link: "#006794",
      primaryExtraDark: "#182D47",
      primaryInverse: "#202020",
      navbarDropdown: "#626262",
      activeBorder: "#46a3b4",
      border: "#d2d2d2",
      // vgInputBorder: "#d7d7d7",
      vgInputBorder: "#b5b1b1",
      btnSecondary: "#c9c9c9",
      btnLight: "#fff",
      btnDark: "#d0d0d0",
      btnPrimary: "#006794",
      textMuted: "#85A9C0",
      bgLight: "#D7E9FB",
      bgGreyLight: "#f5f5f5",
      baseBg: "#E9E8E8",
      containerBg: "#fff",
      formBg: "#e1e1e1",
      moduleBg: "#fff",
      menuBg: "#C9C9C9",
      // iconPrimary: "#858585",
      iconPrimary: "#3c3c3c",
      colorPrimary: "#111010",
      btnBg: "#858585",
      inputBg: "#fff",
      topMenu: "#fff",
      switchOff: "#E5E0DD",
      switchOn: "#F3F1F1",
      gridHeader: "#d7d7d7",
      gridResizer: "#c2c5d5",
      sg1Header: "#c2c5d5",
      sg1Row: "#d9dbea",
      sg1Resizer: "#afafaf",
      sg2Resizer: "#9aafc8",
      sg2Header: "#B5CEEC",
      sg2Row: "#E7F0FB",
      vgEditedBg: "#FFA5FE",
      vgRowInvalid: "#d89b99",
      rowHover: "#d9e2ee",
      statusFatalError: "#ff0",
      statusFatalErrorBg: "#f00",
      statusError: "#BE5252",
      statusErrorBg: "#FBE0E0",
      statusInfo: "#508AC6",
      statusInfoBg: "#E7F0FB",
      statusWarning: "#C08124",
      statusWarningBg: "#FFF9EF",
      statusOk: "#8DB57B",
      statusOkBg: "#EDFCEF",
      // tab: "#4D709B",
      tab: "#037baf",
      subTab: "#00b3f2",
      tabLight: "#dfdfdf",
      filledField: "#6eab1f",
      scroll: "#1d4067",
      eventHavarie: "#f77",
      eventHavarieLight: "#faa",
      eventPreventivniProhlidka: "#7b7",
      eventPreventivniProhlidkaLight: "#ada",
      eventRevizeUniku: "#aaf",
      eventRevizeUnikuLight: "#ccf"
    }
  };

  const DarkTheme: ThemeDefinition = {
    dark: true,
    colors: {
      ...commonColors,
      ...gridRowColors.dark,
      ...serviceColors.dark,
      ...departmentColors.eventTasks.dark,
      "on-surface-variant": "#333",
      blackWhite: "#fff",
      whiteBlack: "#000",
      primary: "#006794",
      secondary: "#0acc95",
      link: "#89dbff",
      primaryExtraDark: "#182D47",
      primaryInverse: "#fff",
      navbarDropdown: "#454545",
      activeBorder: "#99c8d2",
      border: "#7d7d7d",
      vgInputBorder: "#959595",
      btnSecondary: "#c9c9c9",
      btnLight: "#454545",
      btnDark: "#363636",
      bgLight: "#454545",
      bgGreyLight: "#454545",
      baseBg: "#2B2B2B",
      containerBg: "#5D5C5C",
      formBg: "#313131",
      moduleBg: "#5D5C5C",
      menuBg: "#8B8B8B",
      iconPrimary: "#fff",
      colorPrimary: "#fff",
      btnBg: "#5D5C5C",
      btnPrimary: "#54bbe8",
      inputBg: "#9A9A9A",
      switchOff: "#9B9B9B",
      switchOn: "#727070",
      textMuted: "#85A9C0",
      gridHeader: "#494949",
      gridResizer: "#767676",
      // sg1Header: "#515b67",
      sg1Header: "#2e303c",
      // sg1Row: "#545b6c",
      sg1Row: "#343641",
      sg1Resizer: "#6d7a8a",
      sg2Resizer: "#687177",
      sg2Header: "#1e3149",
      sg2Row: "#2c425a",
      vgEditedBg: "#724c6d",
      vgRowInvalid: "#742d2c",
      rowHover: "#575656",
      topMenu: "#494949",
      statusFatalError: "#ff0",
      statusFatalErrorBg: "#f00",
      statusErrorBg: "#302020",
      statusError: "#ff6565",
      statusInfoBg: "#508AC6",
      statusInfo: "#E7F0FB",
      statusWarningBg: "#C08124",
      statusWarning: "#FFF9EF",
      statusOkBg: "#8DB57B",
      statusOk: "#EDFCEF",
      tab: "#058bc5",
      subTab: "#00b3f2",
      tabLight: "#8a8a8a",
      filledField: "#6eab1f",
      scroll: "#87d4f8"
    }
  };

  const vuetify = createVuetify({
    ssr: true,
    theme: {
      defaultTheme: "light",
      themes: {
        light: LightTheme,
        dark: DarkTheme
      },
      variations: {
        colors: ["center102", "center106"],
        lighten: 1,
        darken: 1
      }
    },
    locale: {
      locale: process.env.LANGUAGE_CODE || "cs",
      fallback: "cs",
      messages: {cs}
    },
    display: {
      mobileBreakpoint: "lg",
      thresholds: {
        xs: 0,
        sm: 376,
        md: 501,
        lg: 851,
        xl: 1201,
        xxl: 1441
      }
    },
    defaults: {
      global: {
        ripple: false,
        hideDetails: "auto"
      },
      VBtn: {
        flat: true
      },
      VCard: {
        flat: true,
        elevation: 0
      },
      VExpansionPanels: {
        variant: "accordion",
        multiple: true
      },
      VAutocomplete: {
        variant: "outlined",
        density: "comfortable"
      },
      VFileInput: {
        variant: "outlined",
        density: "comfortable"
      },
      VSelect: {
        variant: "outlined",
        density: "comfortable",
        active: true
      },
      VTextField: {
        variant: "outlined",
        density: "comfortable"
      },
      VTextarea: {
        variant: "outlined",
        density: "comfortable"
      },
      VAlert: {
        variant: "tonal"
      },
      VProgressCircular: {
        indeterminate: true,
        size: 50,
        width: 2
      }
    },
    icons: {
      defaultSet: "mdi",
      sets: {
        mdi,
        neko: iconSet
      }
    }
  });

  // @ts-ignore
  nuxtApp.vueApp.use(vuetify);
});
