<template>
  <div>
    <v-textarea
      v-if="isUpdate || (model != undefined && true && model != '')"
      v-model="model"
      :auto-grow="true"
      rows="1"
      :color="themes[theme || 'default'].color || ''"
      :bg-color="themes[theme || 'default'].bgColor || ''"
      :label="options.label"
      :readonly="!isUpdate"
      :placeholder="placeholder"
      :rules="rulesComp"
      :messages="options.messages"
      :class="model ? '' : 'empty'"
    />
    <v-text-field
      v-if="(model == undefined || null || '') && !isUpdate"
      v-model="model"
      :auto-grow="options.autoGrow"
      :color="themes[theme || 'default'].color || ''"
      :bg-color="themes[theme || 'default'].bgColor || ''"
      :label="options.label"
      :readonly="!isUpdate"
      :placeholder="placeholder"
      :rules="rulesComp"
      :messages="options.messages"
      :rows="rows || nRows"
    />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  options?: FormFieldBaseOptionsRaw,
  isUpdate?: boolean,
  theme?: EditorThemeName,
  rows?: number,
  autoGrow?: boolean,
  height?: number | string,
  withHtmlEditor?: boolean,
  placeholder?: string
}>(), {
  options: () => ({}),
  isUpdate: false,
  theme: "default",
  rows: undefined,
  autoGrow: false,
  height: "90px",
  placeholder: undefined
});

const model = defineModel<string | null>();

const {getRulesWithRequired} = useForms();

const rulesComp = computed(() => getRulesWithRequired(props.options?.required, props.options?.rules));

const ROW_HEIGHT = 14;
const nRows = computed(() => Math.ceil((typeof props.height === "string" ? parseInt(props.height, 10) : props.height) / ROW_HEIGHT));

const themes: {
  [name in "default" | "blue" | "green" | "red" | "yellow"]: {
    color?: string,
    bgColor?: string,
    class?: string,
  }
} = {
  default: {
    color: "cyan"
  },
  blue: {
    color: "primary",
    bgColor: "statusInfoBg",
    class: "statusInfo"
  },
  green: {
    color: "primary",
    bgColor: "statusOkBg",
    class: "statusOk"
  },
  red: {
    bgColor: "statusErrorBg",
    class: "statusError"
  },
  yellow: {
    color: "cyan",
    bgColor: "statusWarningBg",
    class: "statusWarning"
  }
};
</script>
