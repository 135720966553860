export async function useCatchError (promise: Promise<any | any[]>, errorCallback?: (err: string) => void) {
  errorCallback = errorCallback ?? useNotifier().error;
  let promiseResult: any = {};
  try {
    promiseResult = await promise;
  } catch (err) {
    errorCallback(err as string);
    return promiseResult;
  }
  if (Array.isArray(promiseResult)) {
    for (const result of promiseResult) {
      if (result.error?.value) {
        errorCallback(result.error.value);
        return result;
      }
    }
  } else if (promiseResult.error?.value) {
    errorCallback(promiseResult.error.value);
  }
  return promiseResult;
}
