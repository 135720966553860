import type {
  Config,
  DatasourceGetDataParams,
  DeleteCallbackParams,
  GridApi,
  GridColumnDef,
  OnEditModeToggleParams,
  OnRowDropData,
  PaginationState,
  SortingState,
  TopMenuItems,
  ValidationFunction
} from "@olc/vue-grid";
import {VgColumnFilterDate, VgDetailDrawerWithComponents} from "@olc/vue-grid";
import {cloneDeep, get as getDeep, isEmpty, isFunction, merge, mergeWith, set, union, upperFirst} from "lodash-es";
import GridCellObject from "~/components/grid/cell/GridCellObject.vue";
import PlanFilter from "~/components/grid/filters/PlanFilter.vue";
import GridCellPlan from "~/components/grid/cell/GridCellPlan.vue";
import GridCellEditFiles from "~/components/grid/cell/edit/GridCellEditFiles.vue";
import GridCellEdit from "~/components/grid/cell/edit/GridCellEdit.vue";
import GridCellHTML from "~/components/grid/cell/GridCellHTML.vue";
import GridCellDisplayFiles from "~/components/grid/cell/GridCellDisplayFiles.vue";
import VgDateRangeFilter from "~/components/grid/filters/VgDateRangeFilter.vue";
import GridCellFormattedBoolean from "~/components/grid/cell/GridCellFormattedBoolean.vue";
import GridCellCurrency from "~/components/grid/cell/GridCellCurrency.vue";

type ReorderData = { id: string, [x: string]: any }[];

// Cannot import from vuegrid
interface SaveCallbackData<TData = any> {
  grid: GridApi<TData>;
  data: TData[];
  subgrids: SaveCallbackData<TData>[];
}

function mergeUnionArrays(object: any, source1: any, source2?: any) {
  return mergeWith(object, source1, source2, (objValue, srcValue) => {
    if (Array.isArray(objValue)) {
      return union(srcValue, objValue);
    }
  });
}

export const ColumnSizes = {
  header_80: 80,
  header_85: 85,
  header_90: 90,
  header_95: 95,
  header_100: 100,
  header_105: 105,
  header_110: 110,
  header_115: 115,
  header_120: 120,
  header_125: 125,
  header_130: 130,
  header_135: 135,
  header_140: 140,
  header_150: 150,
  header_160: 160,
  header_170: 170,
  header_180: 180,
  header_335: 335,
  chars3: 45,
  chars6: 75,
  chars8: 95,
  chars10: 110,
  chars15: 135,
  chars20: 145,
  chars25: 165,
  chars30: 185,
  chars35: 200,
  chars40: 215,
  chars45: 245,
  chars50: 285,
  chars55: 315,
  chars60: 350,
  chars70: 450,
  chars80: 510,
  chars120: 610,
  chars150: 680,
  chars220: 1045,
  chars340: 1655,
  plan: 275, // keep for edit cell
  center_plan: 190,
  note_for_technician: 225,
  large2: 300,
  extraLarge: 350,
  extraLarge2: 400,
  editColumnMinimum: 105
};

const PAGINATION_OPTIONS = [10, 20, 30, 50, 100]; // max set by max_page_size at services/api/core/grid/grid.py
export const DEFAULT_PAGE_SIZE = 30;

export const ROW_DETAIL_ROW_ID_SUFFIX = "__VG-ROW-DETAIL";
export const NEW_ROW_ID_PREFIX = "vg-new-row-";
export const SUM_ROW_ID_PREFIX = "_sum";

export function useGrid(gridName: string) {
  const {call, callWithFiles} = useApi();
  const {notify} = useNotification();
  const {parseDetailQuery, getUniqueSelectedRows, hashCode, addFnAfter} = useUtils();
  const {formatDate, formatDatetime, formatTimeUnit, formatTime} = useFormat();
  const {validationRules} = useFormValidators();
  // const { getRulesWithRequired } = useForms();
  const {t} = useNuxtApp().$i18n; // cannot use useI18n() outside of script setup

  const ROW_DROP_HANDLERS = {
    reorder: (url: APIPath, orderField = "order", transform?: (reorderData: ReorderData, gridData: any[]) => void) => async ({
      initiator,
      target,
      grid
    }: OnRowDropData) => {
      if (initiator.startsWith(NEW_ROW_ID_PREFIX) || target.startsWith(NEW_ROW_ID_PREFIX)) {
        // eslint-disable-next-line no-console
        console.warn("Cannot reorder new rows.");
        return;
      }
      if (target.startsWith(SUM_ROW_ID_PREFIX)) {
        target = grid.state.data[grid.state.data.length-2].id;
      }
      let initIndex = grid.state.data.findIndex(row => row.id === initiator);
      let targetIndex = grid.state.data.findIndex(row => row.id === target);
      if (initIndex === -1 || targetIndex === -1) {
        // eslint-disable-next-line no-console
        console.warn("Initiator or target row not found.");
        return;
      }
      let minIndex = initIndex;
      let maxIndex = targetIndex;
      if (initIndex > targetIndex) {
        minIndex = targetIndex;
        maxIndex = initIndex;
      }
      const data = [];
      for (let i = minIndex; i <= maxIndex; i++) {
        data.push({
          id: grid.config.getRowId(grid.state.data[i]),
          [orderField]: grid.state.data[i][orderField]
        });
      }
      initIndex -= minIndex;
      targetIndex -= minIndex;
      const [removed] = data.splice(initIndex, 1);
      data.splice(targetIndex, 0, removed);
      // Shift orderField values
      if (initIndex < targetIndex) {
        data[targetIndex][orderField] = data[targetIndex - 1][orderField];
        for (let i = initIndex; i < targetIndex; i++) {
          data[i][orderField] -= 1;
        }
      } else {
        data[targetIndex][orderField] = data[targetIndex + 1][orderField];
        for (let i = targetIndex + 1; i <= initIndex; i++) {
          data[i][orderField] += 1;
        }
      }
      if (transform) {
        transform(data, grid.state.data);
      }
      const {error} = await call(url, "post" as APIPathMethod<typeof url>, {}, data);
      if (error.value) {
        useNotifier().error("save");
        return;
      }
      await grid.fetchData();
    }
  };

  const CSS_CLASSES = {
    rowTransparent: "grid-row-transparent",
    rowBold: "grid-row-bold",
    cellBold: "grid-cell-bold",
    cellShorten: "cell-shorten",
    cellBoldIfRow: "grid-cell-bold-if-row",
    cellTransparentIfRow: "grid-cell-transparent-if-row",
    cellTransparentIfRowAndNoContent: "grid-cell-transparent-if-row-and-no-content",
    rowHideDragAndDrop: "grid-row-hide-drag-and-drop",
    rowHideSelection: "grid-row-hide-selection",
    rowStrikeTroughGreen: "grid-row-strike-trough-green",
    rowStrikeTroughRed: "grid-row-strike-trough-red"
  };

  const baseConfig: Partial<Config<any>> = {
    getRowId: (data: any) => data?.id ? data.id.toString() : hashCode(JSON.stringify(data)),
    setRowId: (data, id) => {
      data.id = id;
    },
    datasource: {
      paginationState: defaultPaginationState(),
      pageCountOptions: PAGINATION_OPTIONS
    },
    enableMultiSort: true,
    enablePinning: true,
    enableGridResizeByUser: false,
    enableDetailDrawer: true,
    detailDrawerProps: {
      location: "right",
      width: 500
    },
    createdRowsLocation: "bottom",
    enableDynamicRendering: false,
    extraOptions: {
      extraFiltersVisible: true,
      accessibility: {
        fontSize: "11px",
        rowHeightMultiplier: 0.4,
        fontFamily: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Oxygen-Sans, Ubuntu, Cantarell, \"Helvetica Neue\", sans-serif"
      }
    },
    extraFilters: [
      {
        columnType: "text",
        id: "extraSearch"
      }
    ],
    topMenuItems: reactive({
      alwaysVisibleHeader: ["edit"],
      alwaysVisible: [
        "clear-filter",
        "refresh",
        "accessibility",
        "column-visibility",
        "supress-detail-drawer"],
      collapsible: [
        "drag-and-drop",
        "reset-settings",
        "global-filters"
      ]
    }) as TopMenuItems,
    componentsPool: {
      detailDrawerComponent: VgDetailDrawerWithComponents,
      filterType: {
        plan_filter: PlanFilter,
        event_plan_filter: PlanFilter,
        material_filter: PlanFilter,
        date_range: VgDateRangeFilter,
        datetime: VgColumnFilterDate,
      }
    }

  };

  type CallbackFn = (({gridApi}: { gridApi: GridApi<any> }) => any);
  const onAfterFetchOnceArray: CallbackFn[] = [];

  function getBaseConfig<P extends APIPath>(name: string, url: P, params?: _CallParamsParams<P, APIPathMethod<P>>, body?: _CallParamsBody<P, APIPathMethod<P>>): Config<any> {
    return {
      ...baseConfig,
      name,
      datasource: {
        ...baseConfig.datasource,
        getData: (datasource: DatasourceGetDataParams<any>) => getData(datasource, url, params, body)
      }
    } as Config<any>;
  }

  function applyEditColumnsMinimumSize<T>(config: Partial<Config<T>>) {
    const MINIMUM_EDIT_COLUMN_SIZE = ColumnSizes.editColumnMinimum;
    if (!config.columns) {
      return;
    }
    for (const column of config.columns) {
      if (column.editable && column.size) {
        column.size = Math.max(column.size, MINIMUM_EDIT_COLUMN_SIZE);
      }
    }
  }

  /**
   * @param name name of the grid
   * @param url API path
   * @param extraConfig config from the gridApi call (e.g. from page)
   * @param config config from the gridApi composable definition
   */
  function getConfig<T extends object, P extends APIPath>(name: string, url: P, extraConfig?: GridApiExtraConfig<P, APIPathMethod<P>>, config: Partial<Config<T>> = {}): Config<T> {
    const baseConfig = getBaseConfig(name, url, extraConfig?.params, extraConfig?.requestBody) as Config<T>;

    if (extraConfig?.hiddenColumns) {
      for (const colId of extraConfig.hiddenColumns) {
        const indexAt = config.columns!.findIndex(column => column.id === colId);
        if (indexAt !== -1) {
          config.columns?.splice(indexAt, 1);
        } else {
          // eslint-disable-next-line no-console
          console.error(`Column ${colId} not found in columns while trying to hide it. \nGrid name: ${baseConfig.name}; \nColumns: ${config.columns!.map(c => c.id).join(", ")}`);
        }
      }
    }

    let finalConfig: Config<T> = mergeUnionArrays(baseConfig, config, extraConfig);

    if (extraConfig?.hidePaginationUnder) {
      finalConfig.enablePagination = false;
      finalConfig = shallowReactive(finalConfig);
    }

    updateTopMenuItems(finalConfig, extraConfig);

    if (extraConfig?.expandRowsFromQuery) {
      onAfterFetchOnceArray.push(({gridApi}) => {
        expandRowsFromQuery(gridApi, extraConfig.expandRowsFromQuery);
      });
    }

    applyEditColumnsMinimumSize(finalConfig);

    finalConfig.onEditModeToggle = addFnAfter(finalConfig.onEditModeToggle, (data: OnEditModeToggleParams<T>) => {
      if (data.targetState) {
        // Ideal solution once everything is in valid state
        // data.grid.validation.validateAll();

        (data.grid as any).originalData = cloneDeep((data.grid.state.data || []).filter((row: any) => !row.id?.startsWith(NEW_ROW_ID_PREFIX)));

        // Temporary solution
        const editDataRowIds: any[] = data.grid.updatedData.map((row: any) => data.grid.config.getRowId(row));
        for (const row of data.grid.table.value?.getRowModel().flatRows ?? []) {
          const rowId = data.grid.config.getRowId(row.original);
          if (rowId.startsWith(NEW_ROW_ID_PREFIX) || editDataRowIds.includes(rowId)) {
            for (const cell of row.getAllCells()) {
              const colDef = data.grid.getColumnDefinitionById(cell.column.id);
              if (colDef) {
                data.grid.validation.validate(rowId, colDef.id, cell.getValue(), row.original, colDef.validationRules);
              }
            }
          }
        }
      } else {
        data.grid.updatedData.length = 0;
        data.grid.state.data = cloneDeep((data.grid as any).originalData ?? []);
      }
    });

    finalConfig.onAfterFetch = addFnAfter(addFnAfter(finalConfig.onAfterFetch, ({gridApi}: { gridApi: GridApi }) => {
      if (extraConfig?.hidePaginationUnder) {
        const newEnablePagination = (gridApi.state.totalRowCount ?? 0) > extraConfig.hidePaginationUnder;
        if (gridApi.config.enablePagination !== newEnablePagination) {
          gridApi.config.enablePagination = newEnablePagination;
        }
      }
      if (extraConfig?.autoExpand) {
        // gridApi.table.value!.toggleAllRowsExpanded(true); DUPLICATES STUFF
        gridApi.table.value!.getRowModel().flatRows.forEach((row) => {
          if (!row.id.endsWith(ROW_DETAIL_ROW_ID_SUFFIX)) {
            row.toggleExpanded(true);
          }
        });
      }
    }), ({gridApi}) => {
      onAfterFetchOnceArray.forEach(callback => callback({gridApi}));
      onAfterFetchOnceArray.length = 0;
    });

    return finalConfig;
  }

  function getGridID(gridId: string): string {
    return `${gridName}_${gridId}_v8`;
  }

  function emptyPaginatedResponse<Type>(): PaginatedResponse<Type> {
    return {
      results: [],
      count: 0,
      next: null,
      previous: null
    };
  }

  function pageCount(response: PaginatedResponse<any> | null, pageSize: number): number {
    if (!response) {
      return 0;
    }
    return Math.ceil(response.count / pageSize);
  }

  function defaultPaginationState(): Partial<PaginationState> {
    return reactive({
      pageSize: DEFAULT_PAGE_SIZE
    });
  }

  function buildSortingQuery(sorts: SortingState) {
    return sorts.map((sort) => {
      return sort.desc ? `-${sort.id}` : sort.id;
    }).join(",");
  }

  /** Specific to current grid - **add translations if used!** */
  function notifyGridError(key: string): void {
    notify({
      title: t(`grids.${gridName}.error.title`),
      text: t(`grids.${gridName}.error.${key}`),
      type: "error"
    });
  }

  /** Specific to current grid - **add translations if used!** */
  function notifyGridSuccess(key: string): void {
    notify({
      title: t(`grids.${gridName}.success.title`),
      text: t(`grids.${gridName}.success.${key}`),
      type: "success"
    });
  }

  /** Specific to current grid - **add translations if used!** */
  function translate(key: string): string {
    return t(`grids.${gridName}.${key}`);
  }

  function translateCommon(key: string): string {
    return t(`grids.common.${key}`);
  }

  function createBasicColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> | null = null): GridColumnDef<T> {
    return merge({}, {
      id: type,
      valueGetter: type,
      headerName: headerName !== undefined && headerName !== null ? upperFirst(headerName) : translateCommon(type.split(".").pop()!),
      enableSorting: true,
      enableColumnFilter: true,
      columnFilterAdvanced: true,
      columnType: "text",
      editCell: GridCellEdit,
      columnFilterCustomData: {
        formField: {
          type: "text"
        }
      } as EditCellColumnFilterCustomData,
      editable: true
    }, options || {});
  }

  function createTextOnlyColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn(
      type,
      headerName,
      merge({}, {
        columnFilterCustomData: {
          formField: {
            type: "textonly"
          }
        } as EditCellColumnFilterCustomData
      }, options)
    );
  }

  function createNumberColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn(
      type,
      headerName,
      merge({}, {
        columnType: "number",
        size: ColumnSizes.chars6,
        justifyContent: "flex-end",
        columnFilterCustomData: {
          formField: {
            type: "number"
          }
        } as EditCellColumnFilterCustomData
      }, options)
    );
  }

  function createCurrencyColumn<T>(type: string, headerName?: string, precision = 0, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn(
      type,
      headerName,
      merge({}, {
        columnType: "number",
        size: ColumnSizes.chars6,
        justifyContent: "flex-end",
        cell: h(GridCellCurrency, { precision } as any),
        columnFilterCustomData: {
          formField: {
            type: "number"
          }
        } as EditCellColumnFilterCustomData
      }, options)
    );
  }

  function createDateColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn(
      type,
      headerName,
      merge({}, {
        columnType: "date_range",
        columnFilterAdvanced: false,
        valueFormatter: (value: string) => formatDate(value),
        columnFilterCustomData: {
          filterOptions: {
            dateType: "date"
          },
          formField: {
            type: "date"
          }
        } as EditCellColumnFilterCustomData,
        editable: true,
        size: ColumnSizes.chars20
      }, options)
    );
  }

  function createDateTimeColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn(
      type,
      headerName,
      merge({}, {
        columnType: "date_range",
        columnFilterAdvanced: false,
        valueFormatter: (value: string) => formatDatetime(value),
        columnFilterCustomData: {
          filterOptions: {
            dateType: "date"
          },
          formField: {
            type: "datetime"
          }
        } as EditCellColumnFilterCustomData,
        size: ColumnSizes.chars25
      }, options)
    );
  }

  function createTimeColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn(
      type,
      headerName,
      merge({}, {
        columnType: "date_range",
        valueFormatter: (value: string) => formatTime(value),
        columnFilterCustomData: {
          formField: {
            type: "time"
          }
        } as EditCellColumnFilterCustomData,
        size: ColumnSizes.chars8
      }, options)
    );
  }


  function createBooleanColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn<T>(
      type,
      headerName,
      merge({}, {
        columnType: "boolean",
        cell: GridCellFormattedBoolean,
        enableColumnFilter: true,
        columnFilterAdvanced: false,
        enableSorting: true,
        columnFilterCustomData: {
          formField: {
            type: "bool"
          }
        } as EditCellColumnFilterCustomData,
        size: ColumnSizes.chars6
      }, options)
    );
  }

  function createPlanColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn<T>(
      type,
      headerName,
      merge({}, {
        columnType: "plan_filter",
        enableColumnFilter: true,
        enableSorting: false,
        columnFilterAdvanced: false,
        size: ColumnSizes.plan,
        cell: GridCellPlan,
        columnFilterCustomData: {
          formField: {
            type: "plan"
          }
        } as EditCellColumnFilterCustomData
      }, options)
    );
  }

  function createPlanMaterialColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createPlanColumn<T>(
      type,
      headerName,
      merge({}, {
        id: "m1",
        columnType: "material_filter"
      }, options)
    );
  }

  function createEventPlanColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createPlanColumn<T>(
      type,
      headerName,
      merge({}, {
        id: "x1",
        columnType: "event_plan_filter"
      }, options)
    );
  }

  function createSelectColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    const col = createBasicColumn(
      type,
      headerName,
      merge({}, {
        columnType: "text",
        enableColumnFilter: true,
        columnFilterAdvanced: true,
        enableSorting: true,
        editable: true,
        cell: GridCellObject,
        columnFilterCustomData: {
          filterFieldName: !Object.keys(options).includes("id") && !options.columnFilterCustomData?.formField?.noObjects
            ? `${type}${options.columnFilterCustomData?.title ? `.${options.columnFilterCustomData.title}` : options.columnFilterCustomData?.formField?.itemTitle ? `.${options.columnFilterCustomData.formField?.itemTitle}` : ""}`
            : type,
          formField: {
            type: "select",
            lazy: true
          }
        } as EditCellColumnFilterCustomData
      }, options)
    );
    return col;
  }

  function createEmailColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn(
      type,
      headerName,
      mergeUnionArrays({}, {
        columnType: "text",
        enableColumnFilter: true,
        columnFilterAdvanced: true,
        enableSorting: true,
        editable: true,
        size: ColumnSizes.chars35,
        validationRules: [validationRules.isEmail],
        columnFilterCustomData: {
          formField: {
            type: "email"
          }
        } as EditCellColumnFilterCustomData
      }, options)
    );
  }

  const noOrderFilterOptions = {
    columnType: "text",
    enableColumnFilter: false,
    enableSorting: false
  };

  function createPrimitiveColumn<T>(type?: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn(
      type ?? "",
      headerName,
      {
        ...noOrderFilterOptions,
        ...options
      }
    );
  }

  function createFileColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn(
      type,
      headerName,
      merge({}, {
        columnType: "text",
        enableColumnFilter: true,
        enableSorting: true,
        size: ColumnSizes.chars40,
        cell: GridCellDisplayFiles,
        editCell: GridCellEditFiles,
        columnFilterCustomData: {
          formField: {
            type: "file",
            multiple: true
          }
        } as EditCellColumnFilterCustomData
      }, options)
    );
  }

  function createTextareaColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn(
      type,
      headerName,
      merge({}, {
        columnType: "text",
        enableColumnFilter: true,
        enableSorting: false,
        editable: true,
        cell: GridCellHTML,
        editCell: GridCellEdit,
        size: ColumnSizes.chars40,
        columnFilterCustomData: {
          formField: {
            type: "textarea",
            rows: 2
          }
        } as EditCellColumnFilterCustomData
      }, options)
    );
  }

  function createColorColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn(
      type,
      headerName,
      merge({}, {
        columnType: "text",
        enableColumnFilter: true,
        enableSorting: false,
        editable: true,
        cell: GridCellHTML,
        editCell: GridCellEdit,
        size: ColumnSizes.chars40,
        columnFilterCustomData: {
          formField: {
            type: "color"
          }
        } as EditCellColumnFilterCustomData
      }, options)
    );
  }

  function createTimeUnitColumn<T>(type: string, headerName?: string, options: Partial<GridColumnDef<T>> = {}): GridColumnDef<T> {
    return createBasicColumn(
      type,
      headerName,
      merge({}, {
        enableColumnFilter: true,
        enableSorting: true,
        columnType: "select",
        editable: true,
        valueFormatter: formatTimeUnit,
        columnFilterCustomData: {
          filterOptions: [
            {value: null, title: "-"},
            {value: 15, title: "čtvrthodina"},
            {value: 30, title: "půlhodina"},
            {value: 60, title: "hodina"}
          ],
          formField: {
            items: [
              {value: 15, title: "čtvrthodina"},
              {value: 30, title: "půlhodina"},
              {value: 60, title: "hodina"}
            ],
            itemTitle: "title",
            itemValue: "value",
            noObjects: true
          }
        } as EditCellColumnFilterCustomData
      }, options)
    );
  }

  async function getData<T, P extends APIPath>(
    datasource: DatasourceGetDataParams<T>,
    url: P,
    params?: _CallParamsParams<P, APIPathMethod<P>>,
    body?: _CallParamsBody<P, APIPathMethod<P>>
  ) {
    const data = await fetchData<T, P>(datasource, url, params, body) as PaginatedResponse<T>;

    return {
      data: data.results,
      pageCount: pageCount(data, datasource.pagination.pageSize),
      totalRowCount: data.count
    };
  }

  async function fetchData<T, P extends APIPath>(
    datasource: DatasourceGetDataParams<T>,
    url: P,
    params?: _CallParamsParams<P, APIPathMethod<P>>,
    body?: _CallParamsBody<P, APIPathMethod<P>>
  ) {
    const {pageIndex, pageSize} = datasource.pagination;

    const columnFiltersTyped = datasource.columnFilters.map((filter) => {
      const column = datasource.gridApi.config.columns.find(column => column.id === filter.id);
      const filterType = column?.columnType ?? "text";
      if (column?.columnFilterCustomData?.filterFieldName) {
        return {
          ...filter,
          filterType,
          id: column.columnFilterCustomData.filterFieldName
        };
      }
      return {
        ...filter,
        filterType
      };
    }).filter(f => !f.filterType.startsWith("_"));

    const {data, error} = await call(
      url,
      "post" as APIPathMethod<P>,
      {
        query: {
          ...params?.query ?? {},
          page: pageIndex + 1,
          page_size: pageSize,
          sort: buildSortingQuery(datasource.sorting)
        },
        path: (params as any)?.path
      } as unknown as _CallParamsParams<P, APIPathMethod<P>>,
      {
        ...body ?? {},
        filters: columnFiltersTyped
      } as _CallParamsBody<P, APIPathMethod<P>>);

    if (data.value) {
      return data.value;
    }
    if (error.value) {
      useNotifier().error("load");
    }
    return emptyPaginatedResponse<T>();
  }

  function getSimpleValidationRules(rules: ValidationFunction<any>[], rowData: any, grid: GridApi, boolReturnOnly = false) {
    return rules.map(rule => (value: any) => boolReturnOnly ? (rule(value, rowData, grid) ? true : false) : rule(value, rowData, grid));
  }

  function validateEditCells<T extends Record<string, any>>(gridApi: GridApi<T>, data: SaveCallbackData<T>) {
    // gridApi.validation.validateAll();
    const changedRows = data.data;

    for (const changedRow of changedRows) {
      const rowId = gridApi.config.getRowId(changedRow) as string;
      const row = gridApi.table.value?.getRow(rowId);
      const original = row?.original ?? {} as T;
      const cells = row?.getAllCells() ?? [];
      for (const cell of cells) {
        const colDef = gridApi.getColumnDefinitionById(cell.column.id);
        if (!colDef) {
          continue;
        }
        const value = getDeep(changedRow, colDef.id);
        // console.log("validateEditCells", changedRow, colDef.id);
        gridApi.validation.validate(rowId, colDef.id, value, original, colDef.validationRules);
      }
    }

    // const errorMessages = gridApi.validation.invalidCells.map(({ columnId, errorMessage }) => {
    //   const colName = gridApi.getColumnDefinitionById(columnId)?.headerName;
    //   return `${colName}: ${errorMessage || "Neplatná hodnota"}`;
    // });
    // if (errorMessages.length) {
    //   notify({
    //     title: "Nesprávné údaje",
    //     text: errorMessages.join("; "),
    //     type: "error"
    //   });
    //   return false;
    // }
    return isEmpty(gridApi.validation.invalidCells.value);
  }

  async function fetchDataWithoutReset<T>(gridApi: GridApi<T>) {

    gridApi.infinityScrollOffset = 0;
    gridApi.blockFutherInfinityScrollFetch.value = false;

    try {
      const res = await gridApi.datasource.getData({
        gridApi,
        pagination: toRaw(gridApi.tableState.value!.pagination),
        sorting: toRaw(gridApi.tableState.value!.sorting),
        columnFilters: toRaw(gridApi.filters.filterState.value)
      });

      (gridApi.tableState.value!.pagination as any).pageCount = res.pageCount;

      if (gridApi.config.enableInfinityScroll) {
        gridApi.tableState.value!.pagination.pageSize = gridApi.config.datasource.paginationState?.pageSize ?? 10;
      }

      // gridApi.resetState();
      for (const row of gridApi.state.data) {
        const rowId = gridApi.config.getRowId(row);
        if (!res.data.find((r: any) => gridApi.config.getRowId(r) === rowId)) {
          if (gridApi.config.createdRowsLocation === "bottom") {
            res.data.push(row);
          } else {
            res.data.unshift(row);
          }
        }
      }
      gridApi.state.data = res.data;

      gridApi.state.totalRowCount = res.totalRowCount;

      gridApi.hideLoader();
    } catch (e) {
      gridApi.hideLoader();
      // eslint-disable-next-line no-console
      console.error(e);
    }
    // sharedBus.emit(GridApiEventKey.rowRenderCallbackTrigger);
    nextTick(() => gridApi.config.onAfterFetch?.({gridApi}));
  }

  function getSaveCallback<P extends APIPath, M extends APIPathMethod<P>, T extends Record<string, any>, P_ extends APIPath, M_ extends APIPathMethod<P_>>(gridApi: GridApi<T>,
    patchGetter?: (row: T) => CallArgs<P, M>,
    postGetter?: (row: T) => CallArgs<P_, M_>,
    hasFiles = false,
    batchPostGetter?: (rows: T[]) => CallArgs<P_, M_>,
    afterSave?: GridApi<any>[],
    afterSaveForm?: (() => void)[],
  ): ((data: SaveCallbackData<T>) => Promise<void>) {
    return async (data: SaveCallbackData<T>) => {
      const changedRows = data.data;

      if (!validateEditCells(gridApi, data)) {
        return;
      }

      let successful = 0;

      const makeCall = async (row: T, isLastRow = false) => {
        const rowId = gridApi.config.getRowId(row) as string;
        const isNew = rowId.startsWith(NEW_ROW_ID_PREFIX);
        const getter = isNew ? postGetter : patchGetter;
        if (getter) {
          // eslint-disable-next-line prefer-const
          let [url, method, params, body, options, noRedirectOn401] = getter(row) as CallArgs<P | P_, APIPathMethod<P | P_>>;
          if (body) {
            if (!isNew) {
              (body as any)._is_last_change = isLastRow;
            }
            if (isNew) {
              (body as any).id = null;
            }
          }
          if (!options) {
            options = {};
          }
          (options as any).key = `${rowId}-${new Date()}`;
          const callMethod = hasFiles ? callWithFiles : call;
          const {error} = await callMethod(...[url, method, params, body as _CallParamsBody<P | P_, APIPathMethod<P | P_>>, options, noRedirectOn401]);
          if (error.value) {
            const notifier = useNotifier();
            const errData = error.value.data;
            if (errData && typeof errData === "object" && "foreign_key_reference" in errData) {
              notifier.errorObject(error.value.data, undefined,
                {notificationOptions: {duration: 3500}},
                key => key === "foreign_key_reference" ? "Položka má existující vazbu na jinou položku" : key);
            } else if (errData && typeof errData === "object") {
              notifier.errorObject(error.value.data, undefined,
                {notificationOptions: {duration: 3500}},
                key => gridApi.getColumnDefinitionById(key)?.headerName || key);
            } else if (errData && typeof errData === "string") {
              const startIndex = errData.indexOf("[");
              const endIndex = errData.indexOf("]");
              const errorMessage = errData.substring(startIndex + 1, endIndex);
              notifier.error(errorMessage);
            } else {
              notifier.error("save");
            }
          } else {
            successful++;
            gridApi.removeFromUpdatedData(rowId);
            if (isNew) {
              gridApi.state.data = gridApi.state.data.filter(r => gridApi.config.getRowId(r) !== rowId);
              // const row = gridApi.table.value?.getRowModel().flatRows.find(r => r.id === rowId);
              // if (row) {
              //   row.original = data.value as T;
              //   row.id = (data.value as T).id as string;
              // }
              useNotifier().success("save");
            }
          }
        } else {
          useNotifier().error("save");
        }
        if (afterSave) {
          afterSave.forEach(item => item.fetchData?.());
        }
        if (afterSaveForm) {
          afterSaveForm.forEach(item => item());
        }
      };

      const makeCallBatch = async (rows: T[]) => {
        const [url, method, params, body, options, noRedirectOn401] = batchPostGetter!(rows) as CallArgs<P | P_, APIPathMethod<P | P_>>;
        const callMethod = hasFiles ? callWithFiles : call;
        const {error} = await callMethod(...[url, method, params, body as _CallParamsBody<P | P_, APIPathMethod<P | P_>>, options, noRedirectOn401]);
        if (error.value) {
          const notifier = useNotifier();
          const errData = error.value.data;
          if (errData && typeof errData === "object") {
            notifier.errorObject(error.value.data, undefined,
              {notificationOptions: {duration: 3500}},
              key => gridApi.getColumnDefinitionById(key)?.headerName || key);
          } else {
            notifier.error("save");
          }
        } else {
          for (const row of rows) {
            successful++;
            const rowId = gridApi.config.getRowId(row) as string;
            gridApi.removeFromUpdatedData(rowId);
          }
          useNotifier().success("save");
        }
      };

      gridApi.showLoader();
      const changedRowsPatch = [];
      const changedRowsPost = [];
      for (const row of changedRows) {
        const rowId = gridApi.config.getRowId(row) as string;
        const isNew = rowId.startsWith(NEW_ROW_ID_PREFIX);
        if (isNew) {
          changedRowsPost.push(row);
        } else {
          changedRowsPatch.push(row);
        }
      }
      if (!batchPostGetter) {
        await Promise.all([
          ...changedRowsPatch.slice(0, -1).map(row => makeCall(row)),
          ...changedRowsPost.map(row => makeCall(row))
        ]);
      } else {
        await Promise.all([
          ...changedRowsPatch.slice(0, -1).map(row => makeCall(row)),
          ...changedRowsPost.length ? [makeCallBatch(changedRowsPost)] : []
        ]);
      }
      if (changedRowsPatch.length) {
        await makeCall(changedRowsPatch[changedRowsPatch.length - 1], true);
      }

      gridApi.hideLoader();

      if (successful === changedRows.length) {
        // Refresh grid
        await gridApi.fetchData();
        gridApi.rerender();
        gridApi.editing.value = false;
      } else {
        if (successful) {
          await fetchDataWithoutReset(gridApi);
          gridApi.rerender();
        }
        if (gridApi.config.saveFailedCallback) {
          await gridApi.config.saveFailedCallback(data);
        }
      }
    };
  }

  function getDeleteCallback<T extends object>(
    gridApi: GridApi<T>,
    pathGetter: (row: T) => string,
    selectedRowsFilter?: (row: T) => boolean,
    afterDelete?: GridApi<any>[] | undefined,
    afterDeleteForm?: (() => void)[] | undefined
  ) {
    return async (data: DeleteCallbackParams<T>) => {
      const rows = data.data;
      gridApi.showLoader();
      const uniqueRows = getUniqueSelectedRows(gridApi, rows).filter(row => !!row && (!selectedRowsFilter || selectedRowsFilter(row)));

      const makeCall = async (row: T, isLastRow = false) => {
        const path = pathGetter(row as T) as APIPath;
        const {error} = await call(path, "delete" as APIPathMethod<typeof path>, undefined, {
          _is_last_change: isLastRow
        });
        if (error.value) {
          const notifier = useNotifier();
          const errData = error.value.data;

          if (errData && typeof errData === "object" && "foreign_key_reference" in errData) {
            notifier.errorObject(error.value.data, undefined,
              {notificationOptions: {duration: 3500}},
              key => key === "foreign_key_reference" ? "Položka má existující vazbu na jinou položku" : key);
          } else if (errData && typeof errData === "string") {
            const startIndex = errData.indexOf("[");
            const endIndex = errData.indexOf("]");
            const errorMessage = errData.substring(startIndex + 1, endIndex);
            notifier.error(errorMessage);
          } else {
            notifier.error("delete");
          }
        }
      };

      await Promise.all(uniqueRows.slice(0, -1).map(row => makeCall(row)));
      await makeCall(uniqueRows[uniqueRows.length - 1], true);

      gridApi.hideLoader();
      // Refresh grid
      gridApi.fetchData();
      if (afterDelete) {
        afterDelete.forEach(item => item.fetchData?.());
      }
      if (afterDeleteForm) {
        afterDeleteForm.forEach(item => item());
      }
    };
  }

  function setCallback<T = any>(gridApi: GridApi<T>, {saveCall, deleteCall}: {
    saveCall?: (data: SaveCallbackData<T>) => Promise<void>,
    deleteCall?: (rows: DeleteCallbackParams<T>) => Promise<void>
  } = {}) {
    if (saveCall) {
      gridApi.config.saveCallback = saveCall;
    }
    if (deleteCall) {
      gridApi.config.deleteCallback = deleteCall;
    }
    updateTopMenuItems(gridApi.config);
  }

  async function expansionHandler(gridApi: GridApi<any>, options: GridApiExtraConfig["expandRowsFromQuery"]) {
    const query = options!.route.query;
    if (!gridApi.table.value || !query[options?.queryAttr ?? "sel"]) {
      return;
    }
    const selectedDetails = parseDetailQuery(query[options?.queryAttr ?? "sel"] as string);

    // gridApi.table.value.toggleAllRowsExpanded(false);
    gridApi.table.value.getExpandedRowModel().flatRows.forEach((row) => {
      if (!selectedDetails.includes(row.id)) {
        row.toggleExpanded(false);
      }
    });
    for (const [i, detail] of selectedDetails.entries()) {
      try {
        gridApi.table.value.getRow(detail).toggleExpanded(true);
      } catch {
        try {
          gridApi.table.value.getRowModel().flatRows.find(r => r.id.includes(detail))?.toggleExpanded(true);
        } catch {
          if (options?.rowNumberGetter && gridApi.config.datasource.paginationState?.pageSize) {
            const rank = await options.rowNumberGetter(detail, i);
            const pageIndex = Math.floor((rank - 1) / gridApi.config.datasource.paginationState.pageSize);
            gridApi.table.value.setPageIndex(pageIndex);
            try {
              gridApi.table.value.getRow(detail).toggleExpanded(true);
            } catch {
              // eslint-disable-next-line no-console
              console.error("Row not found:", detail);
            }
            return;
          } else {
            // eslint-disable-next-line no-console
            console.warn("Row not found:", detail);
          }
        }
      }
    }
  }

  function expandRowsFromQuery(gridApi: GridApi<any>, options: GridApiExtraConfig["expandRowsFromQuery"]) {
    watch(() => options!.route.query, () => {
      if (gridApi.ready) {
        expansionHandler(gridApi, options);
      }
    }, {immediate: true});
  }

  return {
    emptyPaginatedResponse,
    pageCount,
    notifyGridError,
    notifyGridSuccess,
    translate,
    translateCommon,
    createBasicColumn,
    createTextOnlyColumn,
    createNumberColumn,
    createCurrencyColumn,
    createDateColumn,
    createDateTimeColumn,
    createSelectColumn,
    createPrimitiveColumn,
    createTextareaColumn,
    createColorColumn,
    createEmailColumn,
    createTimeUnitColumn,
    createTimeColumn,
    noOrderFilterOptions,
    createFileColumn,
    defaultPaginationState,
    buildSortingQuery,
    getData,
    createBooleanColumn,
    getSaveCallback,
    getDeleteCallback,
    setCallback,
    createPlanColumn,
    createPlanMaterialColumn,
    createEventPlanColumn,
    baseConfig,
    getBaseConfig,
    getConfig,
    getGridID,
    expandRowsFromQuery,
    ROW_DROP_HANDLERS,
    CSS_CLASSES,
    updateTopMenuItems,
    getSimpleValidationRules,
    withOriginalData
  };
}

type ValidationFunctionWithEditData<T> = (value: any, rowOrig: T, rowLive: T, grid: GridApi<T>) => boolean | string;

export function gridGetRowWithUpdatedData<T extends object>(grid: GridApi<T>, rowId: string, original?: T) {
  if (original === undefined) {
    original = grid.table.value?.getRow(rowId)?.original || {} as T;
  }
  const updatedData = cloneDeep(original);
  for (const col of grid.config.columns) {
    const updatedValue = toValue(grid.getUpdatedData(col.id, rowId));
    if (updatedValue !== undefined) {
      set(updatedData, col.id, updatedValue);
    }
  }
  return updatedData;
}

function withOriginalData<T extends object>(validationFunction: ValidationFunctionWithEditData<T>): ValidationFunction<T> {
  return (value, live, grid) => {
    const rowId = grid.config.getRowId(live);
    const orig = grid.table.value?.getRow(rowId)?.original || {} as T;
    // const updatedRow = gridGetRowWithUpdatedData(grid, rowId, orig);
    return validationFunction(value, orig, live, grid);
  };
}

export function updateTopMenuItems(gridConfig: GridApi<any>["config"], extraConfig: GridApiExtraConfig<any> = {}) {
  if (!gridConfig.topMenuItems) {
    gridConfig.topMenuItems = {};
  }
  if (!gridConfig.topMenuItems.alwaysVisibleHeader) {
    gridConfig.topMenuItems.alwaysVisibleHeader = [];
  }

  let newAlwaysVisibleHeader = gridConfig.topMenuItems.alwaysVisibleHeader;
  if (gridConfig.enableEditing) {
    newAlwaysVisibleHeader = union(newAlwaysVisibleHeader, ["edit", "save"]);
  } else {
    newAlwaysVisibleHeader = newAlwaysVisibleHeader.filter(item => item !== "edit" && item !== "save");
  }
  if (gridConfig.deleteCallback && extraConfig.enableDeleting !== false) {
    newAlwaysVisibleHeader = union(newAlwaysVisibleHeader, ["delete"]);
  } else {
    newAlwaysVisibleHeader = newAlwaysVisibleHeader.filter(item => item !== "delete");
  }
  gridConfig.topMenuItems.alwaysVisibleHeader.length = 0;
  gridConfig.topMenuItems.alwaysVisibleHeader.push(...newAlwaysVisibleHeader);
}

function enableEditingNewRowOnly<T>(gridApi: GridApi<T>) {
  const oldEditable: Record<string, any> = {};
  for (const col of gridApi.config.columns) {
    oldEditable[col.id] = col.editable;
    col.editable = ctx => ctx.data.id.startsWith(NEW_ROW_ID_PREFIX) && (isFunction(oldEditable[col.id]) ? oldEditable[col.id](ctx) : oldEditable[col.id]);
  }
  return oldEditable;
}

function disableEditingNewRowOnly<T>(gridApi: GridApi<T>, oldEditable: Record<string, any>) {
  for (const col of gridApi.config.columns) {
    col.editable = oldEditable[col.id];
  }
}

export async function addNewRow<T>(gridApi: GridApi<T>, addToUpdatedData = true) {
  if (!gridApi.config.enableEditing) {
    gridApi.config.enableEditing = true;
    updateTopMenuItems(gridApi.config);
    const oldEditable = enableEditingNewRowOnly(gridApi);

    gridApi.config.onEditModeToggle = addFnAfter(gridApi.config.onEditModeToggle, (data: OnEditModeToggleParams<T>) => {
      if (!data.targetState) {
        gridApi.config.enableEditing = false;
        updateTopMenuItems(gridApi.config);
        disableEditingNewRowOnly(gridApi, oldEditable);
      }
    });
    gridApi.config.saveCallback = addFnAfter(gridApi.config.saveCallback, async ({ grid }: SaveCallbackData<T>) => {
      if (!grid.editing.value) {
        grid.config.enableEditing = false;
        updateTopMenuItems(grid.config);
        disableEditingNewRowOnly(grid, oldEditable);
      }
    });
  }
  let oldRowsIds: string[] = [];
  if (addToUpdatedData) {
    oldRowsIds = gridApi.table.value?.getRowModel().flatRows.map(r => r.id) || [];
  }
  gridApi.createNewRowFromTemplate();
  await nextTick();
  if (addToUpdatedData) {
    const newRow = gridApi.table.value?.getRowModel().flatRows
      .filter(r => r.id.startsWith(NEW_ROW_ID_PREFIX) && !oldRowsIds?.includes(r.id))[0];
    if (newRow) {
      // gridApi.updateRow(newRow.id, newRowData);
      gridApi.updatedData.push(cloneDeep(newRow.original));
    }
  }
  gridApi.editing.value = true;
  gridApi.config.onEditModeToggle?.({grid: gridApi, targetState: true});
}
