<template>
  <div v-if="isMounted" :title="cellTitle" class="w-100" :class="classes">
    <FormLayoutFieldRenderer
      v-model="formField"
      :is-update="true"
    />
  </div>
</template>

<script setup lang="ts">
import { type GridCellContext, useGridContext } from "@olc/vue-grid";
import { get, set } from "lodash-es";
import { gridGetRowWithUpdatedData, NEW_ROW_ID_PREFIX } from "~/composables/grid/useGrid";

const props = defineProps<{
  data: EditCellColumnFilterCustomDataRaw,
  ctx: GridCellContext<any>
}>();

const model = defineModel<any>("value", {
  set: val => toRaw(val)
});
// watch(model, () => {
//   console.log("edit cell model changed", model.value);
// });

const { getValueFormatter } = useFormat();
const { validationRules } = useFormValidators();

const isMounted = ref(!(props.data as any).lazyLoadEditCell);

onMounted(() => {
  setTimeout(() => {
    isMounted.value = true;
  });
});

const { gridApi } = useGridContext();
// const { getSimpleValidationRules } = useGrid("");

const cellTitle = computed(() => {
  if (model.value && typeof model.value === "object") {
    if (props.data.title) {
      return (model.value as Record<string, any>)[props.data.title] || props.data.title;
    }
    const title = (formField.value as FormFieldSelect).itemTitle;
    return title ? (model.value as Record<string, any>)[title] : model.value;
  }
  if (props.ctx.columnDef.columnType === "date") {
    return getValueFormatter("datetime")(model.value);
  }
  return getValueFormatter(props.ctx.columnDef.columnType as FormattedType)(model.value) ?? "";
});

const currentRow = computed(() => {
  if (props.data.useOriginalData) {
    return props.ctx.row.original;
  }
  return gridApi.updatedData.find((row: any) => row.id === props.ctx.row.id) ?? props.ctx.row.original;
});

const formField = computed<FormFieldForRenderer>(
  props.data.formFieldBuilderAllAllRows
    ? () => {
      let builderProps;
      if (currentRow.value) {
        const rows = gridApi.table.value!.getRowModel().flatRows;
        builderProps = props.data.formFieldBuilderAllAllRows?.(
          rows.map(r => gridGetRowWithUpdatedData(gridApi, r.id, r.original)),
          rows!.map((row: any) => row.original));
      } else {
        builderProps = {};
      }
      const field = {
        ...props.data.formField,
        ...currentRow.value ? props.data.formFieldBuilder?.(currentRow.value ?? {}, props.ctx.row.id.startsWith(NEW_ROW_ID_PREFIX) ? {} : { ...props.ctx.row.original }) : {},
        ...builderProps,
        model,
        valueName: "value",
        inputIcon: ""
      };
      return field;
    }
    : (props.data.formFieldBuilderAllRows
      ? () => {
        const field = {
          ...props.data.formField,
          ...currentRow.value ? props.data.formFieldBuilder?.(currentRow.value ?? {}, props.ctx.row.id.startsWith(NEW_ROW_ID_PREFIX) ? {} : { ...props.ctx.row.original }) : {},
          ...currentRow.value ? props.data.formFieldBuilderAllRows?.(gridApi.table.value!.getRowModel().flatRows.map((row: any) => row.original)) : {},
          model,
          valueName: "value",
          inputIcon: ""
        };
        return field;
      }
      : () => {
        const field = {
          ...props.data.formField,
          ...currentRow.value ? props.data.formFieldBuilder?.(currentRow.value ?? {}, props.ctx.row.id.startsWith(NEW_ROW_ID_PREFIX) ? {} : { ...props.ctx.row.original }) : {},
          model,
          valueName: "value",
          inputIcon: ""
        };
        // field.rules = [...field.rules ?? [], ...getSimpleValidationRules(props.ctx.columnDef.validationRules ?? [], currentRow.value, gridApi, true)];
        return field;
      }));

// const { getRulesWithRequired } = useForms();
// const rulesComp = computed(() => getRulesWithRequired(formField.value.required, formField.value.rules));

const invalidCellData = gridApi.validation.getInvalidCellData(props.ctx.row.id, props.ctx.column.id);
const classes = computed(() => {
  if (invalidCellData.value?.message === validationRules.required(null)) {
    return "grid-cell-invalid-required";
  }
  return "";
});

async function validate () {
  await nextTick();
  gridApi.validation.validate(props.ctx.row.id, props.ctx.column.id, model.value, gridGetRowWithUpdatedData(gridApi, props.ctx.row.id), props.ctx.columnDef.validationRules);
}
// const validateDebounced = debounce(validate, 500);
watch(() => toValue(formField.value.readonly), validate);

// onMounted(validateDebounced);
// onUnmounted(() => {
//   gridApi.removeCellInvalid(props.ctx.row.id, props.ctx.column.id);
// });
// watch(rulesComp, validateDebounced);

watch(model, async () => {
  await nextTick();
  const colDef = gridApi.getColumnDefinitionById(props.ctx.column.id);
  if (!colDef || !colDef.columnFilterCustomData.forceValidation) {
    return;
  }
  props.ctx.row.getAllCells().forEach((cell) => {
    if (cell.column.id.startsWith("vg-")) {
      return;
    }
    const colDef = gridApi.getColumnDefinitionById(cell.column.id);
    if (!colDef) {
      return;
    }
    const liveData = gridGetRowWithUpdatedData(gridApi, cell.row.id, cell.row.original);
    let value = get(liveData, cell.column.id);
    if (cell.column.id === props.ctx.column.id) {
      value = model.value;
      set(liveData, cell.column.id, value);
    }
    gridApi.validation.validate(cell.row.id, cell.column.id, value, liveData, colDef.validationRules);
  });
});
</script>
