export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    return;
  }
  const metaAuth = to.meta?.auth ?? true;
  const requiredPermissions: string[] = (to.meta?.permissions as string[]) ?? [];
  const localePath = useNuxtApp().$localePath;
  const authStore = useAuthStore();

  // console.log("preSession", {
  //   data: authStore.sessionData,
  //   isLoggedIn: authStore.isLoggedIn,
  //   accessTokenExpired: authStore.isAccessTokenExpired(),
  //   accessTokenMfaVerified: authStore.isMfaVerified,
  //   accessToken: authStore.parseJwt(authStore.accessToken),
  //   refreshToken: authStore.parseJwt(authStore.refreshToken)
  // });

  if (!authStore.sessionData ||
      (authStore.accessToken !== null &&
      (!authStore.isLoggedIn || authStore.isAccessTokenExpired()))) {
    await authStore.getSession();
    // console.log("postSession", {
    //   data: authStore.sessionData,
    //   isLoggedIn: authStore.isLoggedIn,
    //   accessTokenExpired: authStore.isAccessTokenExpired(),
    //   accessTokenMfaVerified: authStore.isMfaVerified,
    //   accessToken: authStore.parseJwt(authStore.accessToken),
    //   refreshToken: authStore.parseJwt(authStore.refreshToken)
    // });
  }

  if (metaAuth === false) {
    return;
  }

  if (authStore.isLoggedIn) {
    if (requiredPermissions.length === 0 || requiredPermissions.every((perm: string) => authStore.hasPermission(perm))) {
      return;
    }
    return showError(createError({ statusCode: 403 }));
  }

  const query = [localePath({ name: "index" }), localePath({ name: "login" })].includes(to.path)
    ? to.query
    : { next: to.fullPath };
  return navigateTo(localePath({ name: "login", query }));
});
