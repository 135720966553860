<template>
  <div class="form-field-select">
    <v-field
      v-if="!isUpdate"
      ref="selectField"
      color="primary"
      :label="options.label"
      variant="outlined"
      readonly
      :active="model !== undefined && model !== null && model !== ''"
      :model-value="readOnlyValue"
      v-bind="$attrs"
    >
      <div class="form-field-readonly-container">
        <div class="form-field-readonly-container--filler-div">
          <TooltipDataV1 v-if="options.tooltip" :url="options.link" :config="options.tooltip!">
            <slot>{{ readOnlyValue }}</slot>
          </TooltipDataV1>
          <NuxtLink v-else-if="options.link" target="_blank" :to="options.link">
            <slot>{{ readOnlyValue }}</slot>
          </NuxtLink>
          <span v-else>{{ readOnlyValue }}</span>
        </div>
      </div>
    </v-field>
    <v-autocomplete
      v-else-if="!toValue(dataUrl) && !combo"
      ref="selectField"
      v-model="model"
      :return-object="!noObjects"
      type="select"
      :label="options.label"
      :items="toValue(items)"
      :item-title="itemTitle"
      :item-value="itemValue"
      :rules="rulesComp"
      :class="classes"
      :placeholder="options.placeholder"
      :autofocus="options.autofocus"
      :messages="options.messages"
      :clearable="!options.required"
      v-bind="$attrs"
      :chips="!!$attrs.multiple"
    />
    <v-combobox
      v-else-if="!toValue(dataUrl) && combo"
      ref="selectField"
      v-model="model"
      :return-object="!noObjects"
      type="select"
      :label="options.label"
      :items="toValue(items)"
      :item-title="itemTitle"
      :item-value="itemValue"
      :rules="rulesComp"
      :class="classes"
      :placeholder="options.placeholder"
      :autofocus="options.autofocus"
      :messages="options.messages"
      :clearable="!options.required"
      v-bind="$attrs"
      :chips="!!$attrs.multiple"
    />
    <PaginatedAutocomplete
      v-else
      ref="selectField"
      v-model="model"
      :label="options.label"
      :data-url="toValue(dataUrl)"
      :default-items="toValue(items)"
      :item-value="itemValue"
      :item-title="itemTitle"
      :required="options.required"
      :rules="options?.rules"
      :class="classes"
      :placeholder="options.placeholder"
      :autofocus="options.autofocus"
      :messages="options.messages"
      :default-getter="options.defaultGetter"
      clearable
      v-bind="$attrs"
      :chips="!!$attrs.multiple"
      :no-objects="noObjects"
      :combo="combo"
      :lazy="lazy"
      @fetch="$emit('fetch', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { isObject } from "lodash-es";

defineOptions({
  inheritAttrs: false
});
const selectField = ref(null);
defineExpose({ field: selectField });

const props = withDefaults(defineProps<{
  options?: FormFieldBaseOptionsRaw,
  isUpdate?: boolean,
  items?: MaybeRefOrGetter<object[]>,
  dataUrl?: MaybeRefOrGetter<APIPath | string | undefined>,
  itemValue?: string,
  itemTitle: string,
  noObjects?: boolean,
  combo?: boolean,
  lazy?: boolean,
}>(), {
  options: () => ({}),
  isUpdate: false,
  items: () => [],
  itemValue: "id",
  dataUrl: undefined
});

const modelRaw = defineModel<any>();

defineEmits<{ fetch: [Record<string, any>[]] }>();

const { getFieldClasses, getRulesWithRequired } = useForms();

const rulesComp = computed(() => getRulesWithRequired(props.options?.required, props.options?.rules));
const classes = computedAsync(async () => await getFieldClasses(rulesComp.value, model.value), []);

const model = computed({
  get: () => {
    // Fixes empty object rendering in v-autocomplete
    if (modelRaw.value && !Array.isArray(modelRaw.value) && isObject(modelRaw.value) && Object.keys(modelRaw.value).length === 0) {
      return { [props.itemTitle]: "", [props.itemValue]: "" };
    }
    return modelRaw.value;
  },
  set: (value) => {
    modelRaw.value = value;
  }
});

const readOnlyValue = computed(() => {
  const val = model.value && !props.noObjects ? model.value[props.itemTitle] : model.value;

  if (props.options.readonlyFormatter) {
    return props.options.readonlyFormatter(val);
  }
  return val;
});

watchEffect(async (onCleanup) => {
  const items = toValue(props.items);
  if (model.value || !items || items.length === 0 || !props.options.defaultGetter) {
    onCleanup(() => {});
    return;
  }

  const defaultVal = await props.options.defaultGetter(items);
  if (defaultVal !== undefined && defaultVal !== null) {
    model.value = defaultVal;
  }
});

// watchOnce(() => toValue(props.items), (items) => {
//   console.log("WATCH: ", model.value, items, props.options.defaultGetter);
//   if (model.value || !items || items.length === 0 || !props.options.defaultGetter) {
//     return;
//   }

//   const defaultVal = props.options.defaultGetter(items);
//   if (defaultVal !== undefined && defaultVal !== null) {
//     model.value = defaultVal;
//   }
// }, { immediate: true, deep: true });
</script>
