import { cloneDeep } from "lodash-es";
import type { Day, Departure, AbsencesByTechnicianDate, TechnicianAbsence, DayAbsence, AbsencesById, DayMap, VehicleAbsence, SimpleTechnicianService, TechnicianService, GroupedById } from "~/types/service";

export const dayNames = [
  "pondělí",
  "úterý",
  "středa",
  "čtvrtek",
  "pátek",
  "sobota",
  "neděle"
];

export const monthNames = [
  "Leden",
  "Únor",
  "Březen",
  "Duben",
  "Květen",
  "Červen",
  "Červenec",
  "Srpen",
  "Září",
  "Říjen",
  "Listopad",
  "Prosinec"
];

export default function useServiceUtils () {
  const { getHoliday } = useHolidays();
  const { formatISODate } = useFormat();

  function toDayPrecision (date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  function dayFromDate (date: Date, today?: Date | null) {
    const cell: Day = { date: "", classList: [], textContent: "", holidayInfo: "", blank: false };
    const dateStr = formatISODate(date);
    // Do not mark a day as today when today is explicitly set to null
    const todayStr = today !== null ? formatISODate(today || new Date()) : "";

    cell.date = dateStr;
    const holiday = getHoliday(date);
    if (holiday) {
      cell.classList.push("holiday");
      cell.holidayInfo = holiday;
    }
    if (dateStr === todayStr) {
      cell.classList.push("today");
    }
    if (today && toDayPrecision(date) < today) {
      cell.classList.push("past");
    }
    cell.textContent = date.getDate().toString();
    return cell;
  }

  function makeDays (dateFrom: Date, dateTo?: Date, today?: Date | null) {
    dateTo = dateTo || new Date(dateFrom.getFullYear() + 1, dateFrom.getMonth(), dateFrom.getDate());
    const dayCount = Math.floor((dateTo.getTime() - dateFrom.getTime()) / (1000 * 60 * 60 * 24));
    const result: DayMap = {};

    for (let i = 0; i < dayCount; i++) {
      const date = new Date(dateFrom);
      date.setDate(date.getDate() + i);
      const cell = dayFromDate(date, today);
      result[cell.date] = cell;
    }
    return result;
  }

  function daysWithActionIds (days: DayMap, departures: Departure[], inplace = false) {
    if (!inplace) {
      days = cloneDeep(days);
    }

    for (const departure of departures) {
      const actionIds = departure.departure_actions.filter(da => !!da.action).map(da => da.action.id);
      for (const departureDate of departure.departure_dates) {
        const date = safeDate(departureDate.date);
        if (!days[date]) {
          days[date] = dayFromDate(new Date(date), null);
        }
        const day = days[date];
        if (!day.actionIds) {
          day.actionIds = actionIds;
        } else {
          day.actionIds.push(...actionIds);
        }
      }
    }
    return days;
  }

  function daysWithDepartureData (days: DayMap, departures: Departure[], inplace = false) {
    if (!inplace) {
      days = cloneDeep(days);
    }

    const actions: Departure["departure_actions"][0]["action"][] = [];

    for (const departure of departures) {
      if (!departure) {
        continue;
      }
      const dActions = departure.departure_actions.filter(da => !!da.action).map(da => da.action);
      actions.push(...dActions);
      const actionNameString = dActions.map(da => da.name).join(", ");

      for (const departureDate of departure.departure_dates) {
        const date = safeDate(departureDate.date);
        if (!days[date]) {
          days[date] = dayFromDate(new Date(date), null);
        }
        const day = days[date];
        day.isDepartureDate = true;
        day.departureAction = actionNameString;
      }
    }
    let ok: boolean = false;
    for (const day of Object.values(days)) {
      for (const action of actions) {
        ok = false;
        if (day.actionIds?.includes(action.id)) {
          for (const departure_date of (action as any).departure_dates as string[]) { // TODO: fix everything (:
            if (departure_date.startsWith(day.date)) {
              ok = true;
              break;
            }
          }
          if (!ok) {
            // console.log(`\n${name}: action ${action.name} not found on ${day.date}`);
            // console.log("FUCK!!\n")
            continue;
          }
          // console.log(`${name}: found action ${action.name} (${action.internal ? "internal" : "external"}) on ${day.date}`);
          if (!day.actionExternal && !action.internal) {
            day.actionExternal = true;
          } else if (!day.actionInternal && action.internal) {
            day.actionInternal = true;
          }
        }
      }
    }
    return days;
  }

  function daysWithAbsenceData (days: DayMap, absences: DayAbsence[], inplace = false) {
    if (!inplace) {
      days = cloneDeep(days);
    }
    for (const absence of absences) {
      const date = safeDate(absence.date);
      if (!days[date]) {
        days[date] = dayFromDate(new Date(date), null);
      }
      const day = days[date];
      if (!day.absences) {
        day.absences = [];
      }
      day.absences.push(absence);
    }
    return days;
  }

  function daysWithShifts (days: DayMap, services: TechnicianService[], inplace = false) {
    if (!inplace) {
      days = cloneDeep(days);
    }
    for (const service of services) {
      const date = safeDate(service.date);
      if (!days[date]) {
        days[date] = dayFromDate(new Date(date), null);
      }
      const day = days[date];
      if (!day.shifts) {
        day.shifts = {};
      }
      day.shifts[service.technician.center.id] = {
        ids: [...day.shifts[service.technician.center.id]?.ids || [], service.id],
        surnames: [...day.shifts[service.technician.center.id]?.surnames?.split(", ") || [], service.technician.surname].join(", ")
      };
    }
    return days;
  }

  function daysWithShiftIds (days: DayMap, services: SimpleTechnicianService[], inplace = false) {
    if (!inplace) {
      days = cloneDeep(days);
    }
    for (const service of services) {
      const date = safeDate(service.date);
      if (!days[date]) {
        days[date] = dayFromDate(new Date(date), null);
      }
      const day = days[date];
      day.shiftId = service.id;
    }
    return days;
  }

  function daysMarkToday (days: DayMap, today: Date, inplace = false) {
    if (!inplace) {
      days = cloneDeep(days);
    }
    today = toDayPrecision(today);
    const todayStr = formatISODate(today);
    days[todayStr]?.classList.push("today");
    for (const day of Object.values(days)) {
      if (new Date(day.date) < today) {
        day.classList.push("past");
      }
    }
    return days;
  }

  function groupDeparturesByTechnician<T extends Departure> (departures: T[]): GroupedById<T> {
    const output: GroupedById<T> = {};
    for (const departure of departures) {
      for (const departureTechnicians of departure.departure_technicians) {
        if (!output[departureTechnicians.technician.id]) {
          output[departureTechnicians.technician.id] = [];
        }
        output[departureTechnicians.technician.id].push(departure);
      }
    }
    return output;
  }

  function groupDeparturesByDate<T extends Departure> (departures: T[]): GroupedById<T> {
    const output: GroupedById<T> = {};
    for (const departure of departures) {
      for (const departureDate of departure.departure_dates) {
        if (!output[departureDate.date]) {
          output[departureDate.date] = [];
        }
        output[departureDate.date].push(departure);
      }
    }
    return output;
  }

  function groupAbsencesByTechnician (absences: TechnicianAbsence[]) {
    const result: AbsencesById = {};
    for (const absence of absences) {
      if (!result[absence.technician.id]) {
        result[absence.technician.id] = [];
      }
      result[absence.technician.id].push({ ...absence, note: absence.note || "" });
    }
    return result;
  }

  function groupAbsencesByTechnicianDate (absences: TechnicianAbsence[]) {
    const result: AbsencesByTechnicianDate = {};
    for (const absence of absences) {
      if (!result[absence.technician.id]) {
        result[absence.technician.id] = {};
      }
      if (!result[absence.technician.id][absence.date]) {
        result[absence.technician.id][absence.date] = [];
      }
      result[absence.technician.id][absence.date].push({ ...absence, note: absence.note || "" });
    }
    return result;
  }

  function groupAbsencesByVehicle (absences: VehicleAbsence[]) {
    const result: AbsencesById = {};
    for (const absence of absences) {
      if (!result[absence.vehicle.id]) {
        result[absence.vehicle.id] = [];
      }
      result[absence.vehicle.id].push({ ...absence, note: absence.note || "", reason: absence.reason || "" });
    }
    return result;
  }

  return {
    dayFromDate,
    makeDays,
    groupDeparturesByTechnician,
    groupAbsencesByTechnician,
    groupAbsencesByTechnicianDate,
    groupAbsencesByVehicle,
    daysWithActionIds,
    daysWithDepartureData,
    daysWithAbsenceData,
    daysWithShifts,
    daysWithShiftIds,
    daysMarkToday,
    groupDeparturesByDate
  };
}
