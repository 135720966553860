<template>
  <VueDatePicker
    ref="pickerEl"
    v-model="modelComp"
    :format="format"
    locale="cs-CZ"
    :select-text="t('common.select')"
    :cancel-text="t('common.cancel')"
    :teleport="datePickerTeleport"
    :dark="theme.global.current.value.dark"
    text-input
    :start-date="new Date()"
    v-bind="$attrs"
    :clearable="false"
    :class="rootClass"
    :enable-time-picker="props.type !== 'date'"
    :time-picker="props.type === 'time'"
    @open="onOpenSelectDefault"
  >
    <template #dp-input="{ value, onInput, onEnter, onClear, onBlur, onKeypress, onPaste }">
      <v-text-field
        ref="innerTextField"
        color="primary"
        variant="outlined"
        :prepend-inner-icon="(inputIcon !== null && inputIcon !== undefined ? inputIcon : 'mdi-clock-outline') ?? ''"
        :model-value="value"
        v-bind="$attrs"
        :clearable="!$attrs.readonly"
        :rules="($attrs.rules as SimpleInputRule[]).map(r => r ? r(model) : true)"
        :class="fieldClass"
        @input="onInput($event)"
        @keydown.enter="(ev: KeyboardEvent)=>{onEnter(ev); parseIntAsTime()}"
        @keydown.tab="(ev: KeyboardEvent)=>{ev.preventDefault(); parseIntAsTime()}"
        @click:clear="onClear"
        @blur="onBlur"
        @keyup="onKeypress($event)"
        @paste="onPaste"
        @update:focused="$event ? null : parseIntAsTime()"
      />
    </template>
  </VueDatePicker>
</template>
<script setup lang="ts">
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { isObject } from "lodash-es";
import { useTheme } from "vuetify";
import { VTextField } from "vuetify/components";

defineOptions({
  inheritAttrs: false
});

// Declare props explicitly if they cannot be applied
// to both VueDatePicker and v-text-field
const props = withDefaults(defineProps<{
  type?: "date" | "datetime" | "time";
  inputIcon?: string;
  rootClass?: any;
  fieldClass?: any;
  defaultToEndOfDay?: boolean;
}>(), {
  type: "date",
  inputIcon: undefined,
  rootClass: undefined,
  fieldClass: undefined,
  defaultToEndOfDay: false
});

const model = defineModel<any>();
const innerTextField = ref<VTextField | null>(null);

const modelComp = computed({
  get: () => {
    if (!model.value) {
      return null;
    }
    if (props.type === "time") {
      const timeDate = parseTime(model.value);
      if (!timeDate) {
        return null;
      }
      return {
        hours: timeDate.getHours(),
        minutes: timeDate.getMinutes(),
        seconds: timeDate.getSeconds()
      };
    }
    return new Date(model.value);
  },
  set: (value) => {
    if (!value) {
      model.value = null;
    } else if (props.type === "date") {
      if (!(value instanceof Date)) {
        throw new TypeError("Got unexpected value from date picker.");
      }
      model.value = formatISODate(value);
    } else if (props.type === "datetime") {
      if (!(value instanceof Date)) {
        throw new TypeError("Got unexpected value from date picker.");
      }

      if (props.defaultToEndOfDay) {
        value.setHours(23, 59, 0, 0);
      }
      model.value = formatISODatetime(value);
    } else {
      if (!isObject(value) || !("hours" in value)) {
        model.value = value;
        throw new Error("Got unexpected value from time picker.");
      }
      model.value = formatTime(new Date(2000, 1, 1, value.hours, value.minutes, value.seconds));
    }
    nextTick(innerTextField.value?.validate);
  }
});

const format = computed(() => {
  switch (props.type) {
  case "date":
    return DATE_FORMAT;
  case "time":
    return TIME_FORMAT;
  case "datetime":
    return DATETIME_FORMAT;
  default:
    return DATE_FORMAT;
  }
});

const { DATETIME_FORMAT, DATE_FORMAT, TIME_FORMAT, formatISODatetime, formatISODate, formatTime } = useFormat();
const theme = useTheme();
const { t } = useI18n();

const V_OVERLAY_CLASS = ".v-overlay--active";
const vOverlayParent = ref<HTMLElement | null>(null);

const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
});

const pickerEl = ref<any>(null);
watch(pickerEl, (el) => {
  if (isMounted.value) {
    vOverlayParent.value = el?.$el.closest(V_OVERLAY_CLASS);
  }
});

const datePickerTeleport = computed(() => {
  if (isMounted.value) {
    return vOverlayParent.value ? vOverlayParent.value : true;
  }
  return false;
});

const textFieldString = ref<string | null>(null);

if (props.type === "datetime") {
  watch(() => innerTextField.value?.modelValue, (value) => {
    if (value) { textFieldString.value = value; }
  });
}

function parseIntAsTime () {
  const time = textFieldString.value;
  if (props.type !== "datetime" || !time) {
    return null;
  }
  // if time contains 3 or 4 digits, it is in format HMM or HHMM
  if (time.length === 3 || time.length === 4) {
    const hours = parseInt(time.slice(0, -2), 10);
    const minutes = parseInt(time.slice(-2), 10);
    if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
      modelComp.value = new Date(new Date().setHours(hours, minutes, 0, 0));
    }
  }
}

async function onOpenSelectDefault () {
  await nextTick();
  if (pickerEl.value && !model.value) {
    pickerEl.value.updateInternalModelValue(new Date());
  }
}

</script>
