<template>
  <div class="form-field-files">
    <FileDisplayField
      v-if="hostedFiles.length"
      v-model="hostedFiles"
      :writable="true"
    />
    <v-file-input
      v-model="localFilesComp"
      :label="options?.label ?? `Vyberte soubor${multiple ? 'y' : ''}...`"
      :readonly="!isUpdate || options?.readonly"
      prepend-icon=""
      :prepend-inner-icon="options?.inputIcon ?? 'mdi-paperclip'"
      variant="outlined"
      :clearable="!options?.readonly"
      :rules="isUpdate ? rulesComp : []"
      :class="isUpdate ? classes : ''"
      :field-class="isUpdate ? classes : ''"
      :messages="options?.messages"
      :counter="multiple && counter"
      :multiple="multiple"
      @click:clear="onClear"
    >
      <template #selection="{ fileNames }">
        <template v-for="(fileName, index) in fileNames" :key="fileName">
          <v-chip
            v-if="index < 2"
            class="me-2"
            color="secondary"
            size="small"
            density="comfortable"
            label
          >
            {{ fileName }}
          </v-chip>
          <span
            v-else-if="index === 2"
            class="text-overline text-grey-darken-3 mx-2"
          >
            +{{ localFilesComp!.length - 2 }}
          </span>
        </template>
      </template>
    </v-file-input>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  options?: FormFieldBaseOptionsRaw,
  isUpdate?: boolean,
  multiple?: boolean,
  counter?: boolean,
}>();

const { getFieldClasses, getRulesWithRequired } = useForms();
const rulesComp = computed(() => getRulesWithRequired(props.options?.required, props.options?.rules));
const classes = computedAsync(async () => await getFieldClasses(rulesComp.value, [...hostedFiles.value, ...localFilesComp.value]), []);

const hostedFiles = defineModel<string[]>("hostedFiles", { default: () => [] });
const localFiles = defineModel<File[] | null>("localFiles", { default: () => [] });

const localFilesComp = computed({
  get: () => localFiles.value || [],
  set: (value: File[] | null) => {
    // clear sets this to null, cancel on file select sets this to empty array
    if (value === null) {
      localFiles.value = [];
    }
    if (!value || (value && !value.length)) {
      return;
    }

    localFiles.value = [...localFiles.value ?? [], ...value];
  }
});

function onClear () {
  (localFilesComp.value as File[] | null) = null;
}
</script>
