import {isEmpty, merge} from "lodash-es";
import type {
  Action,
  DateRangeRequestBody,
  Departure,
  DepartureActionUpdate,
  DepartureCreateManyRequest,
  DepartureCreateRequest,
  DepartureSerializersWithSimpleTechnicianRelatedData,
  DepartureUpdateRequest,
  DepartureWithDepartureDatesInActions,
  DetailedDeparture,
  GroupedById,
  SingleDateRequestBody,
  Technician,
  TechnicianAbsence,
  TechnicianData,
  TechnicianService
} from "~/types/service";

export default function () {
  const runtimeConfig = useRuntimeConfig();
  const localePath = useLocalePath();
  const {$i18n} = useNuxtApp();
  const route = useRoute();
  const authStore = useAuthStore();
  const {prepareURLParams} = useUtils();

  const baseUrl = computed<string>(() => import.meta.client ? runtimeConfig.public.baseApiUrl : "http://api:8000");

  async function simpleCall<P extends APIPath, M extends APIPathMethod<P>>(
    ...[url, method, params, body, options]: CallArgs<P, M>
  ) {
    const internalOptions: object = {
      headers: {
        "Accept-Language": $i18n.locale.value,
        accept: "application/json"
      },
      method
    };

    const mergedOptions = merge(internalOptions, options ?? {});

    return await useFetch(prepareURLParams(url, (params as any)?.path), {
      ...mergedOptions,
      body: !body || isEmpty(body) ? undefined : body,
      query: params?.query,
      baseURL: baseUrl.value
    }) as CallResponse<P, M>;
  }

  async function call<P extends APIPath, M extends APIPathMethod<P>>(
    ...[url, method, params, body, options, noRedirectOn401]: CallArgs<P, M>
  ) {
    if (authStore.isAccessTokenExpired()) {
      await authStore.refreshAccessToken();
    }
    if (authStore.accessToken) {
      options = merge(options || {}, {headers: {Authorization: authStore.accessTokenHeader}});
    }

    const res = await simpleCall<P, M>(url, method, params, body, options);

    if (res.error.value && [401, 403].includes(res.error.value.statusCode ?? 0) && !noRedirectOn401) {
      navigateTo(localePath({name: "login", query: {next: route.fullPath}}));
    }
    return res as CallResponse<P, M>;
  }

  function getCacheKey(url: any, method: any, params?: Record<string, any>, body?: Record<string, any>) {
    return `${url}_${method}_${JSON.stringify(params)}_${JSON.stringify(body)}`;
  }

  const savedCallKeys: string[] = [];

  function resetCallCache() {
    clearNuxtData(savedCallKeys);
  }

  async function cachedCall<P extends APIPath, M extends APIPathMethod<P>>(
    ...[url, method, params, body, options]: CallArgs<P, M>
  ) {
    const data = ref<any>(null);
    const error = ref<any>(null);

    const cacheKey = getCacheKey(url, method, params, body as Record<string, any>);
    const {data: nuxtData} = useNuxtData(cacheKey);

    if (!nuxtData.value || (!nuxtData.value.data && !nuxtData.value.loading)) {
      nuxtData.value = {
        data: null,
        loading: true,
        onLoadListeners: []
      };

      const res = await call(url, method, params, body, options);
      savedCallKeys.push(cacheKey);

      const listeners = nuxtData.value.onLoadListeners;

      nuxtData.value = {
        data: res.data.value,
        loading: false
      };
      data.value = nuxtData.value.data;

      if (res.error.value) {
        error.value = res.error.value;
      }

      for (const listener of listeners) {
        listener();
      }
    } else if (nuxtData.value.loading) {
      data.value = await new Promise((resolve) => {
        nuxtData.value.onLoadListeners.push(() => {
          resolve(nuxtData.value.data);
        });
      });
    } else {
      data.value = nuxtData.value.data;
    }

    return {data, error} as CallResponse<P, M>;
  }

  async function fetchPdf<P extends APIPath>(
    type: "open" | "download" = "open",
    filename = "file",
    url?: P,
    params?: _CallParamsParams<P, APIPathMethod<P>>,
    body?: _CallParamsBody<P, APIPathMethod<P>>,
    options?: object
  ) {
    if (!options) {
      options = {};
    }
    if (!url) {
      const loc = useRequestURL().hostname.replace("is", "api");
      url = `https://${loc}/api/pdf${route.fullPath}/` as P;
      url = "" as P;
    }

    const callRes = await call(url, "post" as APIPathMethod<P>, params, body, options);
    if (callRes.error.value) {
      if (!(options as any).suppressError) {
        useNotifier().error("load");
      }
      return callRes;
    }

    const blob = new Blob([callRes.data.value as any], {type: "application/pdf"});
    const pdfUrl = URL.createObjectURL(blob);

    try {
      if (type === "open") {
        window.open(pdfUrl, "_blank");
      } else {
        const a = document.createElement("a");
        a.href = pdfUrl;
        a.download = `${filename?.replace(".pdf", "") ?? "file"}.pdf`;
        a.click();
        URL.revokeObjectURL(pdfUrl);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    return callRes;
  }

  async function callWithFiles<P extends APIPath, M extends APIPathMethod<P>>(
    ...[url, method, params, body, options, noRedirectOn401]: CallArgs<P, M>
  ) {
    const {getFormData} = useUtils();
    let internalOptions: object = {
      headers: {
        "Accept-Language": $i18n.locale.value,
        accept: "application/json"
      },
      method
    };

    if (authStore.isAccessTokenExpired()) {
      await authStore.refreshAccessToken();
    }
    if (authStore.accessToken) {
      internalOptions = merge(internalOptions, {headers: {Authorization: authStore.accessTokenHeader}});
    }

    const mergedOptions = merge(internalOptions, options);

    // call (useFetch) is not setting Content-Type header correctly
    const res: { data: any, error: any } = {data: ref(null), error: ref(null)};

    await $fetch(prepareURLParams(url, (params as any)?.path), {
      ...mergedOptions,
      body: !body || isEmpty(body) ? undefined : getFormData(body),
      query: params?.query,
      baseURL: baseUrl.value
    }).catch((err) => {
      res.error.value = err;
    }).then((data) => {
      res.data.value = data;
    });

    if (res.error.value && [401, 403].includes(res.error.value.statusCode ?? 0) && !noRedirectOn401) {
      navigateTo(localePath({name: "login", query: {next: route.fullPath}}));
    }
    return res as CallResponse<P, M>;
  }

  const modules = {
    auth: {
      // async tokenVerify (token: string) {
      //   return await call("/api/auth/token/verify/", "post", {}, { token });
      // },
      async tokenRefresh(accessToken: string, refreshToken: string) {
        return await simpleCall("/api/auth/token/refresh/", "post", {}, {access: accessToken, refresh: refreshToken});
      },
      async verifyOtp(otp: string) {
        return await call("/api/user/otp-verify/", "post", {}, {otp});
      }
    },
    user: {
      async login(username: string, password: string) {
        return await simpleCall("/api/user/login/", "post", {}, {username, password});
      },
      async getSession() {
        return await call("/api/user/session/", "get", {}, {});
      },
      async logout() {
        return await call("/api/user/logout/", "post", {}, {refresh_token: useAuthStore()!.refreshToken as string});
      },
      async changePassword(newPass: string, password: string) {
        return await call("/api/user/change-password/", "post", {}, {new_pass: newPass, password});
      },
      async getOtpQR(oneStepToken?: string) {
        return await call("/api/user/mfa-secret-qr/", "get", {query: oneStepToken ? {token: oneStepToken} : {}}, {});
      },
      async getBackupCodes() {
        return await call("/api/user/backup-codes/", "get", {}, {});
      },
      async generateBackupCodes() {
        return await call("/api/user/backup-codes/", "post", {}, {});
      },
      async resetMfa() {
        return await call("/api/user/reset-mfa/", "post", {}, {});
      }
    },
    files: {
      getReportDetailFileUrl(reportDetailId: string, filename: string) {
        return `${baseUrl.value}/api/department/files/report_detail/${reportDetailId}/${filename}/`;
      },
      getDocumentationFileUrl(documentationId: string, filename: string) {
        return `${baseUrl.value}/api/department/files/documentation/${documentationId}/${filename}/`;
      },
      getNemoFileUrl(nemoFileId: string, filename: string) {
        return `${baseUrl.value}/api/department/files/nemo/${nemoFileId}/${filename}/`;
      },
      getReportDetailZipUrl(reportDetailId: string) {
        return `${baseUrl.value}/api/department/files/report_detail/${reportDetailId}/zip/`;
      },
      getDocumentationZipUrl(documentationId: string) {
        return `${baseUrl.value}/api/department/files/documentation/${documentationId}/zip/`;
      },
      getNemoFileZipUrl(nemoFileId: string) {
        return `${baseUrl.value}/api/department/files/nemo/${nemoFileId}/zip/`;
      },
      getImageFileUrl(relPath: string) {
        return `${baseUrl.value}/api/department/files/image/${relPath}/`;
      },
      async getActionDocumentationZip(id: string, selected: string) {
        return await call("/api/department/files/action_documentation/{id}/{selected}/", "get", {path: {id: id, selected:selected}});
      }
    },
    service: {
      // calendar
      async getTechnicianAbsencesOverview(body: DateRangeRequestBody) {
        return await call("/api/service/absence/technician/overview/", "post", undefined, body);
      },
      async getServiceOverview(body: DateRangeRequestBody) {
        return await call("/api/service/service/date-range-list/", "post", undefined, body);
      },
      async getVehicleAbsenceOverview(body: DateRangeRequestBody) {
        return await call("/api/service/absence/vehicle/date-range-list/", "post", undefined, body);
      },
      async getFreeTechnicianList(body: SingleDateRequestBody) {
        return await call("/api/service/technician/free/", "post", undefined, body);
      },
      async getFreeVehicleList(body: SingleDateRequestBody) {
        return await call("/api/service/vehicle/free/", "post", undefined, body);
      },
      async getTechniciansOverview() {
        return await call("/api/department/technician/overview/", "post", {}, {});
      },
      async createTechnicianAbsence(body: { values: string[], reason: string, note: string }) {
        return await call("/api/service/absence/technician/create2/", "post", {}, body);
      },
      async deleteTechnicianAbsence(body: { values: string[] }) {
        return await call("/api/service/absence/technician/delete2/", "post", {}, body);
      },
      async getVehicleAbsencesOverview(body: DateRangeRequestBody) {
        return await call("/api/service/absence/vehicle/overview/", "post", {}, body);
      },
      async getVehicleOverview(body: { [key: string]: any } = {filters: []}) {
        return await call("/api/department/vehicle/overview/", "post", {}, body as any);
      },
      async createVehicleAbsence(body: { values: string[], reason: string, note: string }) {
        return await call("/api/service/absence/vehicle/create2/", "post", {}, body);
      },
      async deleteVehicleAbsence(body: { values: string[] }) {
        return await call("/api/service/absence/vehicle/delete2/", "post", {}, body);
      },
      async getTechnicianDeparture(body: { date_from: string, date_to: string }) {
        return await call("/api/department/departure/technician/", "post", {}, body);
      },
      async updateServiceBatch(services: { date: string, technician: string, note: string }[]) {
        return await call("/api/service/shifts/update-many/", "post", {}, {services});
      },
      async deleteService(id: string) {
        return await call("/api/service/shifts/delete/{id}/", "delete", {path: {id}});
      },
      async getActionsWithPhase(id?: string, centerIds?: string[]) {
        if (id) {
          return await call("/api/department/action/with_phase/{id}/", "post", {path: {id}}, centerIds ? {center_ids: centerIds} : {});
        }
        return await call("/api/department/action/with_phase/", "get");
      },
      async getDepartureActions(departureId: string) {
        return await call("/api/department/departure/action/list/{departure_id}/", "get", {path: {departure_id: departureId}});
      },
      async updateDeparture(id: string, departure: DepartureUpdateRequest) {
        return await call("/api/department/departure/update/{id}/", "post", {path: {id}}, departure);
      },
      async createDeparture(body: DepartureCreateRequest) {
        return await call("/api/department/departure/create/", "post", {}, body);
      },
      async createMultiDeparture(body: DepartureCreateManyRequest) {
        return await call("/api/department/departure/create-many/", "post", {}, body);
      },
      async copyDeparture(id: string) {
        return await call("/api/department/departure/copy/{id}/", "post", {path: {id}});
      },
      async deleteDeparture(id: string) {
        return await call("/api/department/departure/delete/{id}/", "delete", {path: {id}});
      },
      async combineDepartures(body: { departure_ids: string[] }) {
        return await call("/api/department/departure/combine/", "post", {}, body);
      },
      async detachDepartureDates(body: { departure_id: string, dates: string[] }) {
        return await call("/api/department/departure/detach-dates/", "post", {}, body);
      },
      async getActionDepartures(id: string) {
        return await call("/api/department/departure/of-action/{id}/", "get", {path: {id}});
      },
      async actionsFinishClosed() {
        return await call("/api/department/action/finish-closed/", "post", {}, {});
      },
      async actionsFinishCanceled() {
        return await call("/api/department/action/finish-canceled/", "post", {}, {});
      },
      async departureActionUpdate(departureId: string, actionId: string, body: DeepPartial<DepartureActionUpdate>) {
        return await call("/api/department/departure/action/{departure_id}/{action_id}/", "patch", {
          path: {
            departure_id: departureId,
            action_id: actionId
          }
        }, body);
      },
      async actionRecordsData(id: string) {
        return await call("/api/department/nemo/action_record/{id}/", "get", {path: {id}});
      },
      async actionRecordsToggleClose(id: string, groupIndex: number, close: string) {
        return await call("/api/department/nemo/action_record/{id}/close/", "post", {
          path: {
            id: id,
          }
        }, { close, groupIndex });
      },

      async departureActionOperation(departureId: string, actionId: string, body: DeepPartial<DepartureActionUpdate>) {
        return await call("/api/department/departure/action-operations/{departure_id}/{action_id}/", "post", {
          path: {
            departure_id: departureId,
            action_id: actionId
          }
        }, body);
      },

      async departureActionDelete(departureId: string, actionId: string) {
        return await call("/api/department/departure/action/{departure_id}/{action_id}/", "delete", {
          path: {
            departure_id: departureId,
            action_id: actionId
          }
        });
      },
      async departureActionDetach(departureId: string, actionId: string) {
        return await call("/api/department/departure/action/detach/", "post", undefined, {
          departure_id: departureId,
          action_id: actionId
        });
      },
      async actionUpdate(actionId: string, body: DeepPartial<Action>) {
        return await call("/api/department/action/{id}/", "patch", {
          path: {
            id: actionId
          }
        }, body);
      },
      // pro servis/vzkazy
      async getNotes() {
        return await call("/api/department/note/service/", "get", {}, {});
      },
      async saveNotes(subject?: any, time?: any, note?: string, notedBy?: any) {
        return await call("/api/department/note/service/", "post", {}, {subject, time, note, noted_by: notedBy});
      },
      async getDeparturesScheduled() {
        return await call("/api/service/departure/scheduled/", "get", {}, {});
      },
      async getDepartureDetail(departureId: string) {
        return await call("/api/service/departure/detail/{departure_id}/", "get", {
          path: {
            departure_id: departureId,
          }
        }, {

        });
      },
      async getDeparturesWIP() {
        return await call("/api/service/departure/wip/", "get", {}, {});
      },
      async getDeparturesWithDetailedActions(body: DateRangeRequestBody) {
        return await call("/api/service/departure/overview-detailed/", "post", {}, body);
      },
      // pro servis sluzby
      async getServiceTechniciansShiftsData() {
        return await call("/api/service/shifts/overview/", "post", {}, {});
      },
      async getServicesActionData() {
        return await call("/api/service/shifts/data/", "get", {}, {});
      },
      async departurePdf() {
        return await call("/api/pdf/dispecink/vyjezdy/overview/", "post", {}, {});
      },
      async getNewCoolant() {
        return await call("/api/service/coolant/state/new/", "get", {}, {});
      },
      async getAspiratedCoolant() {
        return await call("/api/service/coolant/state/aspirated/", "get", {}, {});
      },
      async technicianData(dateFrom: Date, dateTo: Date,
        alreadyLoaded?: { technicians?: Technician[] },
        altFetches?: { departures?: (param?: any) => Promise<AsyncData<(Departure | DetailedDeparture)[]>> }
      ) {
        const res: {
          technicianData: TechnicianData,
          technicians: Technician[],
          departures: DepartureWithDepartureDatesInActions[],
          departuresByTechnician: GroupedById<DepartureWithDepartureDatesInActions>,
          services: TechnicianService[],
          absences: TechnicianAbsence[]
        } = {
          technicianData: [],
          technicians: alreadyLoaded?.technicians || [],
          departures: [],
          departuresByTechnician: {},
          services: [],
          absences: []
        };
        const dateRangeBody = {date_from: formatISODate(dateFrom), date_to: formatISODate(dateTo)};
        const defaultFetches = {
          technicians: async () => {
            const r = await modules.service.getTechniciansOverview();
            res.technicians = (r.data.value ?? []).sort((a, b) => {
              return parseInt(a.center.number ?? "0") - parseInt(b.center.number ?? "0");
            });
            return r;
          },
          departures: async () => {
            const r = await modules.service.getTechnicianDeparture({
              date_from: formatISODate(dateFrom),
              date_to: formatISODate(dateTo)
            });
            return r;
          },
          services: async () => {
            const r = await modules.service.getServiceOverview(dateRangeBody);
            res.services = r.data.value || [];
            return r;
          },
          absences: async () => {
            const r = await modules.service.getTechnicianAbsencesOverview(dateRangeBody);
            res.absences = r.data.value || [];
            return r;
          }
        };

        await useCatchError(Promise.all([
          ...!alreadyLoaded?.technicians
            ? [defaultFetches.technicians()]
            : [],
          (altFetches?.departures ? altFetches.departures() : defaultFetches.departures()).then((r) => {
            const tempDepartures: (Departure | DetailedDeparture | DepartureSerializersWithSimpleTechnicianRelatedData)[] = r.data.value || [];
            const departureActionDatesMap: Record<string, string[]> = {};
            for (const departure of tempDepartures) {
              const dates = departure.departure_dates.map(d => d.date);
              for (const da of departure.departure_actions) {
                if (!da.action) {
                  continue;
                }
                if (!departureActionDatesMap[da.action.id]) {
                  departureActionDatesMap[da.action.id] = [];
                }
                departureActionDatesMap[da.action.id].push(...dates);
              }
            }

            for (const departure of (tempDepartures as DepartureWithDepartureDatesInActions[])) {
              for (const da of departure.departure_actions) {
                if (!da.action) {
                  continue;
                }
                da.action.departure_dates = departureActionDatesMap[da.action.id];
              }
            }
            res.departures = tempDepartures as DepartureWithDepartureDatesInActions[];
            res.departuresByTechnician = useServiceUtils().groupDeparturesByTechnician(res.departures);
            return r;
          }),
          defaultFetches.services(),
          defaultFetches.absences()

        ]), (err) => {
          useNotifier().error("load");
          // eslint-disable-next-line no-console
          console.log(err);
        });

        res.technicianData = res.technicians.map(t => ({
          ...t,
          departures: res.departuresByTechnician[t.id] || [],
          services: res.services.filter(s => s.technician.id === t.id),
          absences: res.absences.filter(a => a.technician.id === t.id)
        }));
        return res;
      }
    },
    demand: {
      async getStatisticData(id: string) {
        return await call("/api/department/demand/report/{id}/statistics/", "get", {path: {id}});
      }
    },
    mail: {
      sendMail(mailData: SendMailRequestBody) {
        return callWithFiles("/api/department/documentation/send/", "post", {}, {
          contacts: mailData.contacts,
          subject: mailData.subject,
          message: mailData.message,
          filepath_attachments: mailData.filepath_attachments,
          file_attachments: mailData.file_attachments
        });
      }
    },
    message: {
      async getDispatcherUnreadMessagesCount() {
        return await call("/api/department/message/count-for-dispatcher/", "post", {}, {});
      },
      async getTechnicianUnreadMessagesCount() {
        return await call("/api/service/message/count-for-technician/", "post", {}, {});
      }
    }
  };

  return {
    prepareURLParams,
    call,
    getCacheKey,
    resetCallCache,
    cachedCall,
    simpleCall,
    fetchPdf,
    callWithFiles,
    baseUrl,
    ...modules
  };
}
