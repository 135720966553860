<template>
  <div class="vg-column-filter location-plan">
    <div v-for="i in Array(12).keys()" :key="i">
      <label>{{ i + 1 }}</label>
      <v-checkbox
        :model-value="value[i]"
        class="small-checkbox"
        @update:model-value="(v: boolean) => update(v ?? false, i)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { VgColumnFilterTextProps } from "@olc/vue-grid";
import { computed } from "vue";

const props = defineProps<VgColumnFilterTextProps>();

const writeableValue = props.gridApi.filters.getColumnFilterWriteableData(props.columnId, props.index);

const value = computed({
  get: () => (Array.isArray(writeableValue.value) ? writeableValue.value : Array(12).fill(false)),
  set: (newValue: any) => {
    if (newValue.every((v: any) => v !== true)) {
      writeableValue.value = null;
    } else {
      writeableValue.value = newValue;
    }
  },
});

function update(newValue: boolean, index: number) {
  const newValueArray = [...value.value];
  newValueArray[index] = newValue;
  value.value = newValueArray;
}
</script>