<template>
  <div class="d-flex flex-column align-center plan-div">
    <p class="">
      {{ index + 1 }}
    </p>
    <v-checkbox-btn
      :key="index"
      :model-value="value"
      density="compact"
      @update:model-value="onUpdate"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  value: boolean,
  index: number
}>();

const emit = defineEmits(["onChanged"]);

function onUpdate (val: boolean | null) {
  const updateObject = {
    index: props.index,
    value: val
  };
  emit("onChanged", updateObject);
}
</script>

<style scoped>
.plan-div {
  margin: -1.5px;
}
</style>
